/**
* contact.util.ts
*/
export class ContactUtil {

  public static createRegExpSearch(phone: string): string | unknown {
    let regexFrom: RegExp = undefined;
    if (phone.startsWith('55')) {
      let countryCode = phone.slice(0, 2);
      let areaCode = phone.slice(2, 4);
      let phoneNumber = phone.slice(4);
      if (phoneNumber.length === 9 && phoneNumber.startsWith('9')) {
        phoneNumber = phoneNumber.slice(1);
      }
      regexFrom = new RegExp(`^${countryCode}${areaCode}9?${phoneNumber}$`);
    }
    return regexFrom ?? phone;
  }

}