import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  show() {
    this.loading$.next(true);
    const currentLoadingState = this.loading$.getValue();
  }

  hide() {
    this.loading$.next(false);
    const currentLoadingState = this.loading$.getValue();
  }
}
