import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ContactGroupEditComponent } from './components/contact-group/contact-group-edit/contact-group-edit.component';
import { ContactEditComponent } from './components/contact/contact-edit/contact-edit.component';
import { ContentLoadingAttendanceCardComponent } from './components/content-loading/content-loading-attedance-card/content-loading-attedance-card.component';
import { ContentLoadingCardComponent } from './components/content-loading/content-loading-card/content-loading-card.component';
import { ContentLoadingListComponent } from './components/content-loading/content-loading-list/content-loading-list.component';
import { EmptyRecordsComponent } from './components/empty-records/empty-records.component';
import { FieldErrorComponent } from './components/field-error/field-error.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PreviewMediaComponent } from './components/preview-media/preview-media.component';
import { ModalResponseAttendanceComponent } from './components/response-attendance/modal-response-attendance.component';
import { ModalResponseFastComponent } from './components/response-fast/modal-response-fast.component';
import { SettingsAttendanceComponent } from './components/settings/settings-attendance.component';
import { CnpjTypePipe } from './pipe/cnpj.type.pipe';
import { ContactTypePipe } from './pipe/contact.type.pipe';
import { ResponseTypePipe } from './pipe/response.type.pipe';
import { TagTypePipe } from './pipe/tag.type.pipe';
import { TruncatePipe } from './pipe/truncate.pipe';
import { ContactUploadCsvComponent } from './components/contact/contact-upload-csv/contact-upload-csv.component';
import { AlertModalCheckComponent } from './components/alert-modal-check/alert-modal-check.component';
import { ModalReportAttendanceComponent } from './components/report-attendance/modal-report-attendance.component';
import { SettingsModuleComponent } from '../../public-api';
import { NgxEditorModule } from 'ngx-editor';
import { CSATModuleEditComponent } from './components/settings-module/csat-module-edit/csat-module-edit.component';

const MATERIAL = [
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatChipsModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTableModule,
  MatTabsModule,
  MatGridListModule,
  MatSidenavModule,
  MatToolbarModule,
  MatIconModule,
  MatListModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
];

const DIRECTIVES = [
];

const PIPES = [
  TagTypePipe,
  ResponseTypePipe,
  ContactTypePipe,
  TruncatePipe,
  CnpjTypePipe,
];

const COMPONENTS = [
  ConfirmationComponent,
  ContactEditComponent,
  ContactGroupEditComponent,
  EmptyRecordsComponent,
  PaginatorComponent,
  ModalResponseFastComponent,
  LoadingComponent,
  SettingsAttendanceComponent,
  SettingsModuleComponent,
  PreviewMediaComponent,
  AlertModalComponent,
  ContentLoadingListComponent,
  ContentLoadingCardComponent,
  ContentLoadingAttendanceCardComponent,
  ModalResponseAttendanceComponent,
  ModalReportAttendanceComponent,
  FieldErrorComponent,
  ContactUploadCsvComponent,
  AlertModalCheckComponent,
  CSATModuleEditComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PickerModule,
    ContentLoaderModule,
    NgxEditorModule.forRoot({
      locals: {
        insertLink: 'Adicionar Link',
        removeLink: 'Remover Link',
        // pupups, forms, others...
        url: 'URL',
        text: 'Texto',
        openInNewTab: 'Abrir em nova aba',
        insert: 'Inserir',
        title: 'Título',
        remove: 'Remover',
        enterValidUrl: 'URL inválida',
      }
    }),
    ...MATERIAL,
  ],
  declarations: [
    ...DIRECTIVES,
    ...PIPES,
    ...COMPONENTS,
  ],
  providers: [
    provideNgxMask()
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterLink,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ContentLoaderModule,
    ...MATERIAL,
    ...PIPES,
    ...COMPONENTS,
  ],
  schemas: []
})
export class SharedModule { }

