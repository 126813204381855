import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UserCardComponent } from './user-card.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { NgxMaskDirective } from 'ngx-mask';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    NgxChartsModule,
    NgxMaskDirective,
    MatDatepickerModule
  ],
  declarations: [
    UserCardComponent,
    UserEditComponent
  ],
  exports: [
    UserCardComponent,
    UserEditComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class UserModule { }
