import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { CustomField } from '../models/custom-field.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomFieldService extends AbstractService<CustomField> {

  getURLBase(): string {
    return '/fields-custom';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: CustomField): void {
    delete obj?._id;
  }

  preUpdate(obj: CustomField): void {
  }

  override getList(name?: string): Observable<CustomField[]> {
    const params = name ? { params: { name } } : {};
    return this.http.get<CustomField[]>(this.getURLBase() + '/list', params);
  }
}
