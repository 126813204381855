import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabService {
  private indexTab: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  indexTab$ = this.indexTab.asObservable();

  constructor() { }

  selectedTabIndex(index: number) {
    this.indexTab.next(index);
  }

  getSelectedIndex(): number {
    return this.indexTab.getValue();
  }
}
