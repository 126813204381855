import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Team } from '../models/team.model';
import { Observable } from 'rxjs';
import { Department } from '../models/department.model';
import { Pager } from '../commons/pager';
import { SearchParams } from '../commons/abstract.types';

@Injectable({ providedIn: 'root' })
export class TeamService extends AbstractService<Team> {

  getURLBase(): string {
    return '/team';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Team): void {
    delete obj?._id;
  }

  preUpdate(obj: Team): void {
  }

  getByDepartment(department: string): Observable<Team[]> {
    return super.get(`/byDepartment/${department}`);
  }
  
  getDepartmentFromSupervisor(page: number, perPage: number, search?: string, params?: Partial<SearchParams>): Observable<Pager<Department>> {
    const baseURL = `/departmentFromSupervisor`;
    const pagering = `${baseURL}?page=${page}&perPage=${perPage}`;
    const searching = `${pagering}${!!search ? '&search=' + search : ''}`;
    let urling = `${searching}`;
    if (!!params) {
      for (let param in params) {
        urling = `${urling}&${param}=${params[param]}`;
      }
    }
    return super.get(urling);
  }
  
}
