import { Pipe, PipeTransform } from '@angular/core';
import { ResponseTypeEnum } from '../../models/enum/response.type';

@Pipe({
  name: 'responseType'
})
export class ResponseTypePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    let type = '';
    switch (value) {
      case ResponseTypeEnum.AUTOMATIC:
        type = 'Automática';
        break;
      case ResponseTypeEnum.QUICK:
        type = 'Rápidas';
        break;
      default:
        type = '';
        break;
    }
    return type;
  }
}
