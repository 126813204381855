import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'empty-records-component',
  templateUrl: './empty-records.component.html',
  styleUrls: ['./empty-records.component.scss']
})
export class EmptyRecordsComponent {

  @Input() message: string;
  @Input() subMessage: string;
  @Input() labelButton: string;
  @Output() emitEvent = new EventEmitter();

  emit() {
    this.emitEvent.emit();
  }

}