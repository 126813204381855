import { InjectionToken } from "@angular/core";

export const ENV = new InjectionToken<Environment>('trend_environment');

export const CONSTANTS = new InjectionToken<Constants>('trend_constants');

export interface Environment {
  [key: string]: string | boolean | object | unknown;
}

export interface Constants {
  [key: string]: string;
}