<div class="flex w-full flex-col items-center gap-2">
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8" />
        <path
            d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
            stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <h1 class="font-black text-center text-[20px] text-black">{{ data.title }}</h1>
</div>
<div class="preview-container">
    <span>{{ data.message }}</span>
    <button
      class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
      [mat-dialog-close]="">Fechar</button>
</div>