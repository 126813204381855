import { Component, EventEmitter, Inject, Injector, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent } from '../../../../commons/abstract.component';
import { ContactGroup } from '../../../../models/contact-group.model';
import { Contact } from '../../../../models/contact.model';
import { User } from '../../../../models/user.model';
import { ContactGroupService } from '../../../../services/contact-group.service';

@Component({
  selector: 'contact-group-edit-component',
  templateUrl: './contact-group-edit.component.html'
})
export class ContactGroupEditComponent extends AbstractComponent {

  @Input() user: User;
  @Output() onDelete: EventEmitter<User> = new EventEmitter<User>();
  @Output() onEdit: EventEmitter<User> = new EventEmitter<User>();

  contactGroup: ContactGroup;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ContactGroupEditComponent>,
    public contactGroupService: ContactGroupService,
    @Inject(MAT_DIALOG_DATA) public data: Contact
  ) {
    super(injector);
    this.contactGroup = {} as ContactGroup;
    this.createForm();
  }

  ngOnInit() {
    this.isNew = (!!this.data ? false : true);
    if (!this.isNew) {
      this.contactGroup = this.data;
      this.formGroup.patchValue(this.contactGroup);
    }
  }


  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.contactGroup, value);
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    if (this.isNew) {
      this.contactGroupService.create(this.contactGroup).subscribe({
        next: (value) => {
          this.dialogRef.close(value);
          this.alertService.success('Grupo de Contato salvo com sucesso.');
        },
        error: (err) => {
          this.alertService.error(err.error.message);
        },
        complete: () => {
        },
      });
    } else {
      this.contactGroupService.update(this.contactGroup._id, this.contactGroup).subscribe({
        next: (value) => {
          this.dialogRef.close(value);
          this.alertService.success('Grupo de Contato  salvo com sucesso.');
        },
        error: (err) => {
          this.alertService.error(err.error.message);
        },
        complete: () => {
        },
      });
    }
  }

}
