<a href="javascript:void(0)" class="card-users-wrapper">
  <mat-card class="">
    <mat-card-content >
      <div class="card-users-content">
        <span class="text-sm-bold uppercase mt-10 mb-10">Atendentes online</span>
        <div class="flex container-users-img justify-content-start align-items-center">
          <img src="https://www.nube.com.br/media/noticias/2022/07/04/20653/conheca-o-programa-jovem-aprendiz-e-quem-pode-se-inscrever-400x600.png" alt="" class="card-img-user">
          <img src="https://tangerino.com.br/blog/wp-content/uploads/2020/04/o-que-e-jovem-aprendiz-1024x683.jpg" alt="" class="card-img-user">
          <img src="https://upload.wikimedia.org/wikipedia/commons/1/19/Face-perfil.png" alt="" class="card-img-user">
          <img src="https://salaovirtual.org/wp-content/uploads/2022/04/Dicas-de-Cortes-Desfiados-e-Repicados-4.jpg" alt="" class="card-img-user">
          <img src="https://img.freepik.com/fotos-premium/jovem-brasileiro-isolado-no-fundo-branco-rindo_1368-362553.jpg" alt="" class="card-img-user">
          <img src="https://static.wixstatic.com/media/626a80_ec595fd5c490406da33f438dadae66d5~mv2.jpg/v1/fill/w_350,h_384,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/626a80_ec595fd5c490406da33f438dadae66d5~mv2.jpg" alt="" class="card-img-user">
          <img src="https://dtpr0iglcwflm.cloudfront.net/profissoes/wp-content/uploads/2019/08/7-passos-para-fazer-curriculo-de-jovem-aprendiz-scaled-1600x900.jpg" alt="" class="card-img-user">
          <img src="https://static.wixstatic.com/media/af17e7_a1630eb2ac16468d87046078733ddaf0~mv2.jpg/v1/fill/w_460,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Jovem%20Aprendiz.jpg" alt="" class="card-img-user">

          <button mat-button class="button-more-users">+7</button>
        </div>
      </div>
      <div class="card-users-content mt-20">
        <span class="text-sm-bold uppercase mt-10 mb-10">Gestores online</span>
        <div class="flex container-users-img justify-content-start align-items-center">
          <img src="https://upload.wikimedia.org/wikipedia/commons/d/d1/CARLOS-WARD-PERFIL.png" alt="" class="card-img-user">
          <img src="https://startupnation.com/wp-content/uploads/2019/12/Screen-Shot-2019-12-09-at-3.07.38-PM.jpg" alt="" class="card-img-user">
          <img src="https://businesslink.ca/wp-content/uploads/2021/10/starting-2-e1633646247158.jpg" alt="" class="card-img-user">
          <img src="https://startupnation.com/wp-content/uploads/2019/12/Screen-Shot-2019-12-09-at-3.07.38-PM.jpg" alt="" class="card-img-user">
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</a>
