import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, lastValueFrom } from 'rxjs';
import { AbstractService } from '../../commons/abstract.service';
import { User } from '../../models/user.model';

@Injectable({ providedIn: 'root' })
export class PublicUserService extends AbstractService<User> {

  getURLBase(): string {
    return '/p/user/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: User): void {
    delete obj?._id;
  }
  preUpdate(obj: User): void {
  }

  // REVER
  findByIdPublic(userId: string): Observable<User> {
    return super.get(`${userId}`);
  }

  findByEmailPublic(userEmail: string): Promise<User> {
    return firstValueFrom(super.get(`email/${userEmail}`));
  }

  forgotPassword(email: string): Promise<User> {
    return lastValueFrom(super.post(`${email}/password/forgot`, null));
  }

  resetPassword(userId: string, newPassword: string, confirmedNewPassword: string): Observable<User> {
    const obj = { newPassword, confirmedNewPassword };
    return super.post(`${userId}/password/reset`, obj);
  }

  validation(id: string) {
    return super.get(`${id}/signup/validation/`);
  }

}
