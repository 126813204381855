import { Attendance } from "../models/attendance.model";
import { Channel } from "../models/channel.model";
import { Company } from "../models/company.model";
import { Contact } from "../models/contact.model";
import { ChannelTypeEnum } from '../models/enum/channel.type';
import { MessageTypeEnum } from "../models/enum/message.type.enum";
import { Message } from "../models/message.model";
import { User } from "../models/user.model";

export class MessageHelper {

  public static createMessageFromAttendance(attendance: Attendance,
    type: MessageTypeEnum,
    content: string, contentType: string, idUser: string, caption?: string): Message {

    const channel = attendance.channel;

    const message = {} as Message;
    message.attendance = <Attendance>{ _id: attendance._id };
    message.company = <Company>{ _id: attendance.company._id };
    message.channel = <Channel>{ _id: attendance.channel._id };
    if (channel.type === ChannelTypeEnum.CLOUD_API) {
      message.from = channel.metadata.phoneNumberInfo.display_phone_number;
    } else if (channel.type === ChannelTypeEnum.EVOLUTION_API) {
      message.from = channel.metadata['ownerJid'].replace(/[^0-9]/g, '');
    } else if (channel.type === ChannelTypeEnum.EVOLUTION_GO_API) {
      message.from = channel.metadata['jid'].replace(/^\d+(?=:)|\d+/g, '');
    }
    message.to = attendance.contact.phone;
    message.contact = <Contact>{ _id: attendance.contact._id };//  this.contact;
    message.type = type;
    message.updatedAt = new Date();
    message.content = content;
    message.contentType = contentType;
    message.user = { _id: idUser } as User;

    if (caption) {
      message.caption = caption
    };

    return message;
  }

}
