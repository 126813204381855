import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { ContactGroup } from '../models/contact-group.model';
import { Observable } from 'rxjs';
import { Category } from '../models/category.model';

@Injectable({ providedIn: 'root' })
export class ContactGroupService extends AbstractService<ContactGroup> {

  getURLBase(): string {
    return '/contact-group';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: ContactGroup): void {
    delete obj?._id;
  }

  preUpdate(obj: ContactGroup): void {
  }

  getByIndustry(): Observable<ContactGroup[]> {
    return this.http.get<ContactGroup[]>(this.getURLBase() + '/byindustry');
  }

  getCountContactsByGroup(idsGroup: Array<string>): Observable<unknown> {
    return super.post(`/count/contact/by/groups`, { idsGroup: idsGroup });
  }

  getContactsByGroup(groupId: string): Observable<any> {
    return super.get(`/byGroup/contact/${groupId}`);
  }

  override getList(name?: string): Observable<ContactGroup[]> {
    const params = name ? { params: { name } } : {};
    return this.http.get<ContactGroup[]>(this.getURLBase() + '/list', params);
  }
}
