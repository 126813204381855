import { Injectable } from '@angular/core';
import * as lamejs from '@breezystack/lamejs';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  constructor() { }

  async convertWebMToMp3(webmBlob: Blob): Promise<Blob> {
    const audioBuffer = await this.blobToAudioBuffer(webmBlob);
    const mp3Blob = this.audioBufferToMp3Blob(audioBuffer);
    return mp3Blob;
  }

  private async blobToAudioBuffer(blob: Blob): Promise<AudioBuffer> {
    const arrayBuffer = await this.blobToArrayBuffer(blob);
    const audioContext = new AudioContext();
    return await audioContext.decodeAudioData(arrayBuffer);
  }

  private async blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  }

  private audioBufferToMp3Blob(audioBuffer: AudioBuffer): Blob {
    const sampleRate = audioBuffer.sampleRate;
    const mp3encoder = new lamejs.Mp3Encoder(1, sampleRate, 192);
    const mp3Data = [];

    const sampleBlockSize = 1152;
    const samples = this.convertTo16BitPCM(audioBuffer);

    for (let i = 0; i < samples.length; i += sampleBlockSize) {
      const sampleChunk = samples.subarray(i, i + sampleBlockSize);
      const mp3buf = mp3encoder.encodeBuffer(sampleChunk);
      if (mp3buf.length > 0) {
        mp3Data.push(mp3buf);
      }
    }

    const mp3buf = mp3encoder.flush();
    if (mp3buf.length > 0) {
      mp3Data.push(mp3buf);
    }

    return new Blob(mp3Data, { type: 'audio/mp3' });
  }

  private convertTo16BitPCM(audioBuffer: AudioBuffer): Int16Array {
    const channelData = audioBuffer.getChannelData(0);
    const length = channelData.length;
    const result = new Int16Array(length);

    for (let i = 0; i < length; i++) {
      result[i] = this.floatTo16BitPCM(channelData[i]);
    }
    return result;
  }

  private floatTo16BitPCM(input: number): number {
    const s = Math.max(-1, Math.min(1, input));
    return s < 0 ? s * 0x8000 : s * 0x7FFF;
  }
}