import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'alert-modal-check',
    templateUrl: './alert-modal-check.component.html',
    styleUrls: ['./alert-modal-check.component.scss'],
})
export class AlertModalCheckComponent {
    isChecked: boolean = false;

    constructor(
        public _matDialogRef: MatDialogRef<AlertModalCheckComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string },
    ) {
    }

    ngOnInit(): void {
    }

    onClose(): void {
        this._matDialogRef.close();
    }
}