<div class="flex flex-col w-full justify-center items-center text-center pt-10">
  <div class="relative">
    <svg class="mt-30" width="200" viewBox="0 0 157 157" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3987_21674)">
        <circle cx="78.625" cy="78.625" r="28.375" stroke="url(#paint0_linear_3987_21674)" stroke-width="1.5"
          shape-rendering="crispEdges" />
      </g>
      <circle cx="78.625" cy="78.625" r="51.375" stroke="url(#paint1_linear_3987_21674)" stroke-width="1.5" />
      <circle cx="78.5" cy="78.5" r="77.25" stroke="url(#paint2_linear_3987_21674)" stroke-width="1.5" />
      <path
        d="M70.5 70.5H86.5V82.5H71.67L70.5 83.67V70.5ZM70.5 68.5C69.4 68.5 68.51 69.4 68.51 70.5L68.5 88.5L72.5 84.5H86.5C87.6 84.5 88.5 83.6 88.5 82.5V70.5C88.5 69.4 87.6 68.5 86.5 68.5H70.5ZM72.5 78.5H84.5V80.5H72.5V78.5ZM72.5 75.5H84.5V77.5H72.5V75.5ZM72.5 72.5H84.5V74.5H72.5V72.5Z"
        fill="#4213F6" />
      <defs>
        <filter id="filter0_d_3987_21674" x="45.6" y="48.6" width="66.05" height="66.05" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.95" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.999316 0 0 0 0 0.999316 0 0 0 0 0.999316 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3987_21674" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3987_21674" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_3987_21674" x1="78.625" y1="49.5" x2="78.625" y2="97.2949"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#D0D0FF" />
          <stop offset="1" stop-color="#597EFF" stop-opacity="0.02" />
        </linearGradient>
        <linearGradient id="paint1_linear_3987_21674" x1="78.625" y1="26.5" x2="78.625" y2="112.038"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#D0D0FF" />
          <stop offset="1" stop-color="#597EFF" stop-opacity="0.02" />
        </linearGradient>
        <linearGradient id="paint2_linear_3987_21674" x1="78.5" y1="0.5" x2="78.5" y2="128.5"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#D0D0FF" />
          <stop offset="1" stop-color="#597EFF" stop-opacity="0.02" />
        </linearGradient>
      </defs>
    </svg>
  </div>
  <div class="flex justify-center flex-col items-center -mt-10">
    <h2 class="text-black font-black text text-2xl">{{ message }}</h2>
    <h5 *ngIf="subMessage" class="text-gray-600 font-light my-3">{{ subMessage }}</h5>
    <button *ngIf="labelButton"
      class="mt-3 w-[250px] h-[40px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
      (click)="emit()">{{ labelButton }}</button>
  </div>

</div>