import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Observable } from 'rxjs';
import { Flow } from '../models/flow.model';

@Injectable({ providedIn: 'root' })
export class FlowService extends AbstractService<Flow> {

  getURLBase(): string {
    return '/flow';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Flow): void {
    delete obj?._id;
  }

  preUpdate(obj: Flow): void {
  }

  async findAll(): Promise<Observable<Flow[]>> {
    const flows = await super.get('')
    return flows
  }

}
