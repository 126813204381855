import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Webhook } from '../models/webhook.model';

@Injectable({ providedIn: 'root' })
export class WebhookService extends AbstractService<Webhook> {

  getURLBase(): string {
    return '/webhook';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Webhook): void {
    delete obj?._id;
  }
  preUpdate(obj: Webhook): void {
  }

}  