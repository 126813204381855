import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Observable } from 'rxjs';
import { SettingsModule } from '../models/settings-module.model';

@Injectable({ providedIn: 'root' })
export class SettingsModuleService extends AbstractService<SettingsModule> {

  getURLBase(): string {
    return '/settings-module';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: SettingsModule): void {
    delete obj?._id;
  }

  preUpdate(obj: SettingsModule): void {
  }

  getByChannel(idChannel: string): Observable<SettingsModule> {
    return super.get(`/find/channel/${idChannel}`);
  }
}
