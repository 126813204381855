import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { Pager } from '../commons/pager';

@Injectable({ providedIn: 'root' })
export class UserService extends AbstractService<User> {

  getURLBase(): string {
    return '/user';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: User): void {
    delete obj?._id;
  }
  preUpdate(obj: User): void {
  }

  resetPassword(idUser: string, obj: {newPassword: string, confirmedNewPassword: string }) : Observable<User> {
    return super.put(`/set/reset/newPassword/${idUser}`, obj);
  }

  updatePassword(idUser: string, obj: { currentPassword: string, newPassword: string, confirmedNewPassword: string }) : Observable<User> {
    return super.put(`/set/update/newPassword/${idUser}`, obj);
  }

  findByAttendant(): Observable<User[]> {
    return super.get(`/attendant`);
  }

  resendConfirmationEmail(idUser: string): Observable<void> {
    return super.put(`/${idUser}/email/resend-confirmation/`, null);
}

}  