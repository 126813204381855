import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Chatbot } from '../models/chatbot.model';

@Injectable({ providedIn: 'root' })
export class ChatbotService extends AbstractService<Chatbot> {

  getURLBase(): string {
    return '/chatbot';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Chatbot): void {
    delete obj?._id;
  }

  preUpdate(obj: Chatbot): void {
  }

  getChatbotByCompanyAndChannel(idCompany: string, idChannel: string): Observable<Chatbot> {
    return super.get(`/get/company/${idCompany}/channel/${idChannel}`);
  }

}
