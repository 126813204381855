import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadMessageService {
  private eventLoadMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  eventLoadMessage$ = this.eventLoadMessage.asObservable();

  constructor() { }

  setLoadingMessage(event: boolean) {
    this.eventLoadMessage.next(event);
  }

  getValueLoading(): boolean {
    return this.eventLoadMessage.getValue();
  }
}
