import { Component, ElementRef, Inject, Injector, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import * as Papa from 'papaparse';
import { Subscription } from 'rxjs';
import { AbstractComponent } from '../../../../commons/abstract.component';
import { ContactUtil } from '../../../../helpers/contact.util';
import { UtilHelper } from '../../../../helpers/util.helper';
import { Contact } from '../../../../models/contact.model';
import { ContactGroupService } from '../../../../services/contact-group.service';
import { ContactService } from '../../../../services/contact.service';
import { DepartmentService } from '../../../../services/department.service';
import { TagService } from '../../../../services/tag.service';
import { AlertModalComponent } from '../../alert-modal/alert-modal.component';
import { ConfirmationComponent } from '../../confirmation/confirmation.component';
import { FirebaseService, UploadTypeEnum } from '../../../../../public-api';
import { ImportationService } from '../../../../services/importation.service';
import { Importation } from '../../../../models/importation.model';

@Component({
  selector: 'contact-upload-csv-component',
  templateUrl: './contact-upload-csv.component.html',
  styleUrls: ['./contact-upload-csv.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactUploadCsvComponent extends AbstractComponent implements OnDestroy {

  csvContent: any[] = [];
  paginatedContent: any[] = [];
  pageSize = 10;
  currentPage = 0;
  url: string = '';
  filename: string = '';
  contentType: string = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild('inputFile', { static: false }) inputFile: ElementRef<HTMLInputElement>;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ContactUploadCsvComponent>,
    public contactService: ContactService,
    public contactGrupoService: ContactGroupService,
    public tagService: TagService,
    public departmentService: DepartmentService,
    public dialog: MatDialog,
    private modalAlert: MatDialog,
    private firebaseService: FirebaseService,
    private importationService: ImportationService,
    @Inject(MAT_DIALOG_DATA) public data: Contact,
  ) {
    super(injector);
    this.createForm();
  }

  ngOnInit() {
  }

  override ngOnDestroy(): void {
  }

  private createForm(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  handleFileSelect(event: any) {
    const file = event.target.files[0];
    const fileTypeLimit = UtilHelper.getFileTypeLimit(file.type);
    if (fileTypeLimit && file.size > fileTypeLimit.maxSize) { // Check type and max size of file
      this.openModalAlert(fileTypeLimit.alertTitle, fileTypeLimit.alertMessage);
      return;
    }

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        this.loading = true;
        const dateMilisecond = new Date().getTime();
        const filename: string = file.name + '_' + dateMilisecond.toString();
        this.filename = file.name;
        this.contentType = file.type;
        this.firebaseService.uploadFile(file, filename, UploadTypeEnum.CSV).then((snapshot: { url: string }) => {
          this.url = snapshot.url;
          this.loading = false;
        });
        const csv = reader.result as string;

        // Parse the CSV content
        Papa.parse(csv, {
          header: true,
          complete: (results) => {
            const uniquePhones = new Set<string>();
            this.csvContent = results.data;
            this.csvContent = this.csvContent.filter((item: any) => item['telefone']);
            this.csvContent = this.csvContent.map((item: any, index: number) => {
              const phonePattern = /^-?(0|[1-9]\d*)?$/;
              const emailPattern = this.emailRegex;
              const phone = item['telefone'];
              const email = item['email'];
              let isEmailValid = true;
              if (email) {
                isEmailValid = emailPattern.test(email)
              }
              let validPhone = true;
              if (phone.startsWith('55')) {
                validPhone = phone.length === 13 || phone.length === 12;
              }
              const phoneRegex = ContactUtil.createRegExpSearch(phone);
              phoneRegex
              if (phoneRegex && uniquePhones.has(phoneRegex.toString())) {
                return null;
              }
              if (phoneRegex) {
                uniquePhones.add(phoneRegex.toString());
              }
              return {
                'name': item['nome'] ? item['nome'] : item['telefone'],
                'phone': phone,
                'email': item['email'],
                'tags': item['etiquetas'],
                'index': index,
                'hasError': !phonePattern.test(phone) || !isEmailValid || !validPhone
              }
            }).filter(item => item !== null);
            this.updatePaginatedContent();
          },
          error: (err) => {
            console.error('Error parsing CSV:', err);
          }
        });
      };

      reader.readAsText(file);
    }
  }

  save() {
    if (!this.csvContent || this.csvContent.length === 0) {
      this.alertService.error('Nenhum contato carregado.');
      return;
    }
    if (this.csvContent.length - this.getNumberContactErrors() === 0) {
      this.alertService.error('Nenhum contato válido carregado.');
      return;
    }
    const importation = {
      filename: this.filename,
      contentType: this.contentType,
      url: this.url,
      metadata: {
        totalSend: this.csvContent.length,
        totalExists: 0,
        totalInserted: 0,
        totalUpdated: 0,
        totalCreatedTag: 0,
        totalError: this.getNumberContactErrors()
      }
    } as Importation;
    this.importationService.create(importation).subscribe({
      next: () => {
        this.alertService.success('Importação agendada com sucesso.');
      },
      error: (err) => {
        this.alertService.error('Ops! Ocorreu um erro ao tentar agendar a importação de contatos. Tente novamente mais tarde.');
      },
      complete: () => {
        this.dialogRef.close();
      }
    });
  }

  getHeaders(): string[] {
    return this.csvContent.length ? Object.keys(this.csvContent[0]) : [];
  }

  updatePaginatedContent(): void {
    const start = this.currentPage * this.pageSize;
    const end = start + this.pageSize;
    this.paginatedContent = this.csvContent.slice(start, end);
  }

  handlePage(event: any): void {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updatePaginatedContent();
  }

  delete(item: any) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.csvContent = this.csvContent.filter((i: any) => i.index !== item.index);
        this.updatePaginatedContent();
      }
    });
  }

  getNumberContactErrors(): number {
    return this.csvContent.filter((i: any) => i.hasError).length;
  }

  private openModalAlert(title: string, message: string): void {
    const dialogRefAlert = this.modalAlert.open(AlertModalComponent, {
      width: '600px',
      maxHeight: '300px',
      data: { title: title, message: message },
    });
    dialogRefAlert.afterClosed().subscribe(() => this.inputFile.nativeElement.value = '');
  }
}