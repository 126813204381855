import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AbstractComponent } from '../../../commons/abstract.component';
import { UtilHelper } from '../../../helpers/util.helper';
import { Response } from '../../../models/response.model';

export type PreviewMediaType = 'upload' | 'edit' | 'view';

export interface PreviewMediaData {
  midia?: string;
  response?: Response;
  isTableResponseApp?: boolean;
  message?: string;
  filename?: string;
  url?: string;
  type?: 'image' | 'file' | 'location' | 'audio' | 'video' | 'document';
};

@Component({
  selector: 'preview-media',
  templateUrl: './preview-media.component.html',
  styleUrls: ['./preview-media.component.scss'],
})
export class PreviewMediaComponent extends AbstractComponent {
  loadingSpinnerSubject = new BehaviorSubject<boolean>(true);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  textDescription!: string;

  isEdit: boolean = false;

  mode!: PreviewMediaType;

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: PreviewMediaData,
    private _matDialogRef: MatDialogRef<PreviewMediaComponent>,
  ) {
    super(injector);
    this.mode = this.declareMode(this.data);
  }

  ngOnInit(): void {
    this.handleData(this.data);
  }

  showPreview(url: string): void {
    this.data.midia = url;
    this.data.type = this.helperExtension(url);
    this.textDescription = 'Sua mídia foi carregada com sucesso.';
    this.loadingSpinnerSubject.next(false);
  }

  onSend(): void {
    this._matDialogRef.close(this.data);
  }

  onCancel(): void {
    this._matDialogRef.close(null);
  }

  getFileNameFromUrl(urlMidia: string): string {
    if (!urlMidia) return 'N/A';

    const decodedUrl = decodeURIComponent(urlMidia);
    const parts = decodedUrl.split('/');

    const fileNameWithQuery = parts[parts.length - 1];
    return fileNameWithQuery.split('?')[0];
  }

  toggleEdit(): void {
    this.isEdit = !this.isEdit;
  }

  private helperExtension(midia: string): 'image' | 'file' | 'location' | 'audio' | 'video' | 'document' {
    const decodedUrl = decodeURIComponent(midia);

    const fileNameWithQuery = decodedUrl.split('/').pop() || '';
    const fileName = fileNameWithQuery.split('?')[0];

    const extension = fileName.split('.').pop()?.toLowerCase();

    const regex = /_(\d+)/;
    const nameWithoutPart = extension.replace(regex, '');

    const mimeType = UtilHelper.getMimeType(nameWithoutPart);

    switch (mimeType) {
      case 'video':
        return 'video';
      case 'document':
        return 'document';
      case 'audio':
        return 'audio';
      case 'image':
        return 'image';
      default:
        return 'file'
    }
  }

  private declareMode(data: PreviewMediaData): PreviewMediaType {
    if (data.midia) {
      return 'edit';
    } else if (data.isTableResponseApp) {
      return 'view';
    } else {
      return 'upload';
    }
  }

  private handleData(data: PreviewMediaData): void {
    if (this.mode === 'upload') {
      this.textDescription = 'Sua mídia está sendo carregada, aguarde um instante.';
      this.loadingSpinnerSubject.next(true);
    } else if (this.mode === 'view') {
      this.showPreview(this.data.response.midia);
    } else {
      this.showPreview(data.midia);
    }
  }
}
