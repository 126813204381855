
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONSTANTS, Constants } from '../commons/injection.token';
import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  constructor(private http: HttpClient, @Inject(CONSTANTS) private constants: Constants) { }

  login(email: string, password: string): Observable<User> {
    return this.http.post('/auth', { email: email, password: password })
      .pipe(
        map((response: any) => {
          let currentUser = response;
          if (currentUser.token) {
            localStorage.setItem(this.constants['currentUser'], JSON.stringify(currentUser));
            return currentUser.user as User;
          } else {
            throw observableThrowError(currentUser.message);
          }
        })
      );
  }

  loginImpersonate(idUser: string, idCompany: string): Observable<User> {
    return this.http.post('/auth/impersonate', { idUser, idCompany })
      .pipe(
        map((response) => {
          let currentUser: any = response;
          if (currentUser && currentUser.token) {
            localStorage.setItem(this.constants['currentUser'], JSON.stringify(currentUser));
            return currentUser.user as User;
          } else {
            throw observableThrowError(currentUser.message);
          }
        })
      );
  }

  refreshJwtTokenUserLogged(): Observable<User> {
    return this.http.get('/auth/refresher/')
      .pipe(
        map((response: any) => {
          let currentUser: any = response;
          if (currentUser && currentUser.token) {
            localStorage.setItem(this.constants['currentUser'], JSON.stringify(currentUser));
            return currentUser.user as User;
          } else {
            throw observableThrowError(currentUser.message);
          }
        })
      );
  }

  logout() {
    localStorage.removeItem(this.constants['currentUser']);
  }

}
