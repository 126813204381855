import { Component } from '@angular/core';

@Component({
  selector: 'lib-lib-trend-core',
  standalone: true,
  imports: [],
  template: `
    <p>
      lib-trend-core works!
    </p>
  `,
  styles: ``
})
export class LibTrendCoreComponent {

}
