import { Component } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Alert } from './alert.model';
import { AlertService } from './alert.service';

@Component({
  selector: 'alert',
  template: ''
})
export class AlertComponent {

  message: any;
  private currentTimeout: any;

  optionsToastr: IndividualConfig = {
    positionClass: 'toast-top-right',
    progressBar: true,
    closeButton: true,
    tapToDismiss: false,
    enableHtml: true,
    timeOut: 7000,
  } as IndividualConfig;

  constructor(private alertService: AlertService, private toastr: ToastrService) { }

  ngOnInit() {
    this.alertService.getMessage().subscribe(
      (alert: Alert) => {
        if (alert) {
          if (alert.type === 'info') {
            this.toastr.info(
              alert.message,
              alert.title ?? 'Notificação',
              this.optionsToastr
            );
          } else if (alert.type === 'success') {
            this.toastr.success(
              alert.message,
              alert.title ?? 'Sucesso',
              this.optionsToastr
            );
          } else if (alert.type === 'error') {
            this.toastr.error(
              alert.message,
              alert.title ?? 'Ops!',
              this.optionsToastr
            );
          } else if (alert.type === 'warning') {
            this.toastr.warning(
              alert.message,
              alert.title ?? 'Atenção!',
              this.optionsToastr
            );
          }
        }
      });
  }


}
