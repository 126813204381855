import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TemplateMessageObject } from '@trendbuild/trend-cloud-api';
import { Observable } from 'rxjs';
import { AttendanceStatusEnum } from '../../public-api';
import { AbstractService } from '../commons/abstract.service';
import { Attendance } from '../models/attendance.model';

@Injectable({ providedIn: 'root' })
export class AttendanceService extends AbstractService<Attendance> {

  getURLBase(): string {
    return '/attendance';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Attendance): void {
    delete obj?._id;
  }

  preUpdate(obj: Attendance): void {
  }

  createFromTemplate(
    idDepartment: string,
    idChannel: string,
    idContact: string,
    template: TemplateMessageObject,
    link: string,
    params: string[]
  ): Observable<Attendance> {
    const obj = { idDepartment, idChannel, idContact, template, link, params };
    return super.post('/create', obj);
  }

  addRemoveTag(obj: { idTag: string, idAttendance: string }): Observable<Attendance> {
    return super.put(`/addRemoveTag/${obj.idAttendance}`, obj);
  }

  existsContactStatus(contactId: string, statusList: string[], channelId: string): Observable<Boolean> {
    let status = "";
    if (statusList && statusList.length > 0) {
      status = "&status=" + statusList.join("&status=");
    }
    return super.get(`/contacts/${contactId}/exists?v=1${status}&channelId=${channelId}`);
  }

  updateStatus(id: string, status: AttendanceStatusEnum): Observable<Attendance> {
    return super.get(`/update/status/${id}?status=${status}`);
  }

  assignUser(id: string, idUser: string): Observable<Attendance> {
    return super.get(`/assign/user/${id}?idUser=${idUser}`);
  }

  closeAttendance(idAttendance: string): Observable<Attendance> {
    return super.put(`/close/attendance/${idAttendance}`, { idAttendance });
  }

  updateMessagesStatus(attendanceId: string, read: boolean): Observable<void> {
    return super.put(`/up-att/${attendanceId}`, { read })
  }

  transferAttendance(attendanceId: string, idDepartment: string, idUser?: string): Observable<Attendance> {
    return super.put(`/transfer/attendance/${attendanceId}`, { idDepartment, idUser });
  }

  getCountAttendancesByContacts(idsContact: string[]): Observable<unknown> {
    return super.post(`/count/attendances/by/contacts`, { ids: idsContact });
  }

  getCountAttendancesByStatus(): Observable<Array<{ status: string, count: number }>> {
    return super.get('/count/attendances/by/status');
  }

  updateCountUnreadMessages(idAttendance: string): Observable<void> {
    return super.get('/update/count/unread/messages/' + idAttendance);
  }

}
