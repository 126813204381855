import { Component } from '@angular/core';

@Component({
  selector: 'content-loading-card',
  templateUrl: './content-loading-card.component.html',
  styleUrls: ['./content-loading-card.component.scss']
})
export class ContentLoadingCardComponent {

  constructor() { }

}