import { Component } from '@angular/core';

@Component({
  selector: 'content-loading-list',
  templateUrl: './content-loading-list.component.html',
  styleUrls: ['./content-loading-list.component.scss']
})
export class ContentLoadingListComponent {

  constructor() { }

}