import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CONSTANTS, Constants } from './injection.token';
import { RoleUserEnum } from '../../public-api';

@Injectable()
export class AuthGuardAdmin implements CanActivate {

  constructor(private router: Router, @Inject(CONSTANTS) private constants: Constants) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (localStorage.getItem(this.constants['currentUser'])) {
      const ls = JSON.parse(localStorage.getItem(this.constants['currentUser']));
      const roles = ls['user']['roles'] ?? [];
      if (roles.includes(RoleUserEnum.ADMIN)) {
        return true;
      }
    }
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
