/**
* data.util.ts
*/
import { addDays, addHours, addMonths, endOfMonth, format, parseISO, setHours, setMinutes, setSeconds, startOfMonth, subMinutes } from 'date-fns';


export class DateUtil {

  static toFormart(dateStrOrDate: string | Date, formatDate = 'dd/MM/yyyy'): string {
    const dated = (typeof dateStrOrDate === 'string' ? parseISO(dateStrOrDate) : dateStrOrDate);
    dated.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
    return format(dated, formatDate);
  }

  static getDateAddDays(amountDays: number, date: Date = new Date()) {
    date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    return addDays(date, amountDays);
  }

  static getDateAddMonths(amountMonths: number, date: Date = new Date()) {
    date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    return addMonths(date, amountMonths);
  }

  static getDateAddDaysInitialTime(amountDays: number, date: Date = new Date()) {
    date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    let newDate = addDays(date, amountDays);
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    let newDateInitial = new Date(Date.UTC(year, month, day, 0, 0, 0));
    newDateInitial.setTime(newDateInitial.getTime() + newDateInitial.getTimezoneOffset() * 60 * 1000); // deserializa
    return newDateInitial;
  }

  static getDateAddDaysFinalTime(amountDays: number, date: Date = new Date()) {
    date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    let newDate = addDays(date, amountDays);
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    var newDateFinal = new Date(Date.UTC(year, month, day, 23, 59, 59));
    newDateFinal.setTime(newDateFinal.getTime() + newDateFinal.getTimezoneOffset() * 60 * 1000); // deserializa
    return newDateFinal;
  }

  static getDateAddMonthInitialTime(amountMonth: number, date: Date = new Date()) {
    date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    let newDate = addMonths(date, amountMonth);
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    let newDateInitial = new Date(Date.UTC(year, month, day, 0, 0, 0));
    newDateInitial.setTime(newDateInitial.getTime() + newDateInitial.getTimezoneOffset() * 60 * 1000); // deserializa
    return newDateInitial;
  }

  static getDateAddMonthFinalTime(amountMonth: number, date: Date = new Date()) {
    date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    let newDate = addMonths(date, amountMonth);
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    var newDateFinal = new Date(Date.UTC(year, month, day, 23, 59, 59));
    newDateFinal.setTime(newDateFinal.getTime() + newDateFinal.getTimezoneOffset() * 60 * 1000); // deserializa
    return newDateFinal;
  }

  static getFirstAndLastDateOfTheMonth(year: number, month: number) {
    const date = new Date(year, month);
    const startDate = startOfMonth(date);
    const endDate = endOfMonth(date);

    return { startDate, endDate };
  }

  static getDate24HoursAgo() {
    let date = new Date();
    date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
    let newDate = addHours(date, -23);
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    let hours = newDate.getHours();
    let mins = newDate.getMinutes();
    let secs = newDate.getSeconds();
    var newDateFinal = new Date(Date.UTC(year, month, day, hours, mins, secs));
    newDateFinal.setTime(newDateFinal.getTime() + newDateFinal.getTimezoneOffset() * 60 * 1000); // deserializa
    return newDateFinal;
  }

  static getExpirationConversationDate() {
    return addDays(new Date(), 1);
  }

  static getDateSubtractMinuts(amountMinutes: number, date: Date = new Date()) {
    date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
    return subMinutes(date, amountMinutes);
  }

  static getDiffMinutesFromDate(date: Date): number {
    const now = new Date();
    const mileseconds = now.getTime() - date.getTime();
    return ((mileseconds / 1000) / 60); // mileseconds seconds and then to minutes
  }

  static getDateSubstractDays(days: number): Date {
    const date: Date = new Date();
    date.setDate(date.getDay() - days);
    return date;
  }

  static getDateSubstractHours(hours: number): Date {
    const date: Date = new Date();
    date.setHours(date.getHours() - hours);
    return date;
  }

  static getDateSubstractMinutes(minutes: number): Date {
    const date: Date = new Date();
    date.setMinutes(date.getMinutes() - minutes);
    return date;
  }
}