import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent } from '../../../commons/abstract.component';
import { User } from '../../../models/user.model';

@Component({
  selector: 'app-user-card',
  templateUrl: 'user-card.component.html'
})
export class UserCardComponent extends AbstractComponent implements OnInit {

  // @Input() pager: Pager<User>;
  @Input() user: User;
  @Output() onDelete: EventEmitter<User> = new EventEmitter<User>();
  @Output() onEdit: EventEmitter<User> = new EventEmitter<User>();

  constructor(
    public injector: Injector,
    public dialog: MatDialog,) {
    super(injector);
  }

  ngOnInit() {
  }

  delete(user: User) {
    this.onDelete.emit(user);
  }

  edit(user?: User) {
    this.onEdit.emit(user);
  }

}