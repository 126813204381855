<a href="javascript:void(0)" class="card-graphic-medium-wrapper">
  <mat-card class="">
    <mat-card-content >
      <div class="card-graphic-medium-content">
        <span class="text-sm text-mutted mt-10">{{label}}</span>
      </div>
      <div class="conatiner-graphic-medium" *ngIf="graphicType && graphicType==='bar'">
        <ngx-charts-bar-vertical
          [view]="[250,280]"
          [results]="single"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [doughnut]="true"
          (select)="onSelect($event)">
        </ngx-charts-bar-vertical>
      </div>
      <div class="conatiner-graphic-medium" *ngIf="graphicType && graphicType==='doughnut'">
        <ngx-charts-pie-chart
          [view]="[250,280]"
          [legend]="false"
          [labels]="false"
          [legendTitle]="''"
          [view]="[250,280]"
          [results]="single"
          [doughnut]="true">
        </ngx-charts-pie-chart>
      </div>
    </mat-card-content>
  </mat-card>
</a>
