import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { ENV, Environment } from '../../commons/injection.token';

@Injectable({ providedIn: 'root' })
export class SocketService implements OnDestroy {

  private socket: Socket | undefined = undefined;

  private connected$ = new BehaviorSubject<boolean>(false);

  constructor(@Inject(ENV) private environment: Environment) { }

  private get socketActived() {
    return (this.environment['socketActived'] === true);
  }

  public connect() {
    if (this.socketActived && !this.socket) {
      this.socket = io(String(this.environment['trendWebsocketUrl']), { reconnection: true });
      this.socket.on('connect', () => this.connected$.next(true));
      this.socket.on('disconnect', () => this.connected$.next(false));
      this.connected$.asObservable().subscribe(connected => {
        console.log('Socket connected: ', connected);
      });
    }
  }

  public disconnect() {
    if (this.socketActived && this.socket) {
      this.socket.disconnect();
      this.socket = null;
      this.connected$.next(false);
    }
  }

  public listen(event: string): Observable<any> {
    if (this.socketActived && this.socket) {
      return new Observable(observer => {
        this.socket.on(event, data => {
          observer.next(data);
        });
        return () => {
          this.socket.off(event);
        }
      })
    } else {
      return new Observable()
    }
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

}