import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private isExpandedSubject = new BehaviorSubject<boolean>(true);
  isExpanded$ = this.isExpandedSubject.asObservable();

  setExpandedState(state: boolean) {
    this.isExpandedSubject.next(state);
  }

  toggleExpandedState() {
    this.isExpandedSubject.next(!this.isExpandedSubject.value);
  }
}
