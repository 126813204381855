import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-icon-component',
  templateUrl: './card-icon.component.html'
})
export class CardIconComponent {

  @Input() icon: string;
  @Input() title: string;
  @Input() label: string;
  @Input() link: string;

  constructor() { }

  ngOnInit() {

  }
}
