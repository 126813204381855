import { Pipe, PipeTransform } from '@angular/core';
import { TagTypeEnum } from '../../models/enum/tag.type';
import { ContactTypeEnum } from '../../../public-api';

@Pipe({
  name: 'contactType'
})
export class ContactTypePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    let tag = '';
    switch (value) {
      case ContactTypeEnum.SITE:
        tag = 'Site';
        break;
      case ContactTypeEnum.ANNOUNCEMENT:
        tag = 'Anúncio';
        break;
      case ContactTypeEnum.INFLUENCER:
        tag = 'Influencer';
        break;
      case ContactTypeEnum.INSTAGRAM:
          tag = 'Instagram';
          break;
      default:
        tag = '';
        break;
    }
    return tag;
  }
}
