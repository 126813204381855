<div class="bg-white p-3 shadow rounded-lg w-100 h-[230px] border-2 border-gray-100">
  <div class="flex w-100 justify-between items-center pb-2 border-b-2 border-gray-100">
    <div class="flex gap-3 flex-1 ">
      <img class="w-8 h-8 rounded-full object-cover" [src]="getUserImage(user)" alt="{{user?.name}}">
      <span class="text-sm text-ellipsis overflow-hidden">{{user?.name}}</span>
    </div>
    <div class="inline-block text-left" x-data="{ menu: false }">
      <button x-on:click="menu = ! menu" type="button"
        class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none relative" id="menu-button"
        aria-expanded="true" aria-haspopup="true">
        <span class="sr-only"></span>
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
          aria-hidden="true">
          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>
      </button>
      <div x-show="menu" x-on:click.away="menu = false"
        class="absolute w-40 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
        role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
        <div class="" role="none">
          <a href="javascript:void(0)"
            class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
            role="menuitem" tabindex="-1" id="menu-item-0" (click)="edit(user)">
            Editar
          </a>
        </div>
        <div class="" role="none">
          <a href="javascript:void(0)"
            class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
            role="menuitem" tabindex="-1" id="menu-item-0" (click)="delete(user)">
            Remover
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col h-full justify-start items-start mt-2 gap-2">
    <div>
      <label class="text-sm font-light text-gray-400 uppercase">EMAIL:</label>
      <h5 class="font-light text-sm">{{user.email}}</h5>
    </div>
    <div>
      <label class="text-sm font-light text-gray-400">Telefone:</label>
      <h5 class="font-light text-sm">{{user.phone}}</h5>
    </div>
    <div class="flex flex-col">
      <label class="text-sm font-light text-gray-400 w-100">Departamento:</label>
      <div class="flex">
        <i class="ph-light ph-chart-bar"></i>
        <h5 class="font-light text-sm"> Nome do departamento</h5>
      </div>
    </div>
  </div>
</div>