import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
function ContentLoaderComponent__svg_animate_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "animate", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵattribute("keyTimes", ctx_r0.keyTimes)("values", ctx_r0.animationValues[0])("dur", ctx_r0.duration);
  }
}
function ContentLoaderComponent__svg_animate_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "animate", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵattribute("keyTimes", ctx_r0.keyTimes)("values", ctx_r0.animationValues[1])("dur", ctx_r0.duration);
  }
}
function ContentLoaderComponent__svg_animate_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "animate", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵattribute("keyTimes", ctx_r0.keyTimes)("values", ctx_r0.animationValues[2])("dur", ctx_r0.duration);
  }
}
function uid() {
  return Math.random().toString(36).substring(2);
}
class ContentLoaderComponent {
  constructor() {
    this.fixedId = uid();
    this.idClip = `${this.fixedId}-diff`;
    this.idGradient = `${this.fixedId}-animated-diff`;
    this.idAria = `${this.fixedId}-aria`;
    this.animate = true;
    this.baseUrl = '';
    this.speed = 1.2;
    this.viewBox = '0 0 400 130';
    this.gradientRatio = 2;
    this.backgroundColor = '#f5f6f7';
    this.backgroundOpacity = 1;
    this.foregroundColor = '#eee';
    this.foregroundOpacity = 1;
    this.rtl = false;
    this.interval = 0.25;
    this.style = {};
    this.animationValues = [];
  }
  ngOnInit() {
    this.clipPath = `url(${this.baseUrl}#${this.idClip})`;
    this.fillStyle = {
      fill: `url(${this.baseUrl}#${this.idGradient})`
    };
    this.style = this.rtl ? {
      ...this.style,
      ...{
        transform: 'scaleX(-1)'
      }
    } : this.style;
    this.duration = `${this.speed}s`;
    this.keyTimes = `0; ${this.interval}; 1`;
    this.animationValues = [`${-this.gradientRatio}; ${-this.gradientRatio}; 1`, `${-this.gradientRatio / 2}; ${-this.gradientRatio / 2}; ${1 + this.gradientRatio / 2}`, `0; 0; ${1 + this.gradientRatio}`];
  }
}
/** @nocollapse */ /** @nocollapse */
ContentLoaderComponent.ɵfac = function ContentLoaderComponent_Factory(t) {
  return new (t || ContentLoaderComponent)();
};
/** @nocollapse */ /** @nocollapse */
ContentLoaderComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ContentLoaderComponent,
  selectors: [["content-loader"]],
  inputs: {
    animate: "animate",
    baseUrl: "baseUrl",
    speed: "speed",
    viewBox: "viewBox",
    gradientRatio: "gradientRatio",
    backgroundColor: "backgroundColor",
    backgroundOpacity: "backgroundOpacity",
    foregroundColor: "foregroundColor",
    foregroundOpacity: "foregroundOpacity",
    rtl: "rtl",
    interval: "interval",
    style: "style"
  },
  ngContentSelectors: _c0,
  decls: 12,
  vars: 15,
  consts: [[3, "ngStyle"], ["role", "presentation", "x", "0", "y", "0", "width", "100%", "height", "100%", 3, "ngStyle"], ["role", "presentation"], ["offset", "0%"], ["attributeName", "offset", "repeatCount", "indefinite", 4, "ngIf"], ["offset", "50%"], ["offset", "100%"], ["attributeName", "offset", "repeatCount", "indefinite"]],
  template: function ContentLoaderComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelementStart(0, "svg", 0);
      i0.ɵɵelement(1, "rect", 1);
      i0.ɵɵelementStart(2, "defs", 2)(3, "clipPath");
      i0.ɵɵprojection(4);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "linearGradient")(6, "stop", 3);
      i0.ɵɵtemplate(7, ContentLoaderComponent__svg_animate_7_Template, 1, 3, "animate", 4);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(8, "stop", 5);
      i0.ɵɵtemplate(9, ContentLoaderComponent__svg_animate_9_Template, 1, 3, "animate", 4);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(10, "stop", 6);
      i0.ɵɵtemplate(11, ContentLoaderComponent__svg_animate_11_Template, 1, 3, "animate", 4);
      i0.ɵɵelementEnd()()()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngStyle", ctx.style);
      i0.ɵɵattribute("viewBox", ctx.viewBox);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngStyle", ctx.fillStyle);
      i0.ɵɵattribute("clip-path", ctx.clipPath);
      i0.ɵɵadvance(2);
      i0.ɵɵattribute("id", ctx.idClip);
      i0.ɵɵadvance(2);
      i0.ɵɵattribute("id", ctx.idGradient);
      i0.ɵɵadvance();
      i0.ɵɵattribute("stop-color", ctx.backgroundColor)("stop-opacity", ctx.backgroundOpacity);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.animate);
      i0.ɵɵadvance();
      i0.ɵɵattribute("stop-color", ctx.foregroundColor)("stop-opacity", ctx.foregroundOpacity);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.animate);
      i0.ɵɵadvance();
      i0.ɵɵattribute("stop-color", ctx.backgroundColor)("stop-opacity", ctx.backgroundOpacity);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.animate);
    }
  },
  dependencies: [i1.NgStyle, i1.NgIf],
  styles: ["[_nghost-%COMP%]{display:block}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentLoaderComponent, [{
    type: Component,
    args: [{
      selector: 'content-loader',
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [`
      :host {
        display: block;
      }
    `],
      template: "<svg [attr.viewBox]=\"viewBox\" [ngStyle]=\"style\">\n  <rect role=\"presentation\" [attr.clip-path]=\"clipPath\" [ngStyle]=\"fillStyle\" x=\"0\" y=\"0\" width=\"100%\" height=\"100%\" />\n\n  <defs role=\"presentation\">\n    <clipPath [attr.id]=\"idClip\">\n      <ng-content></ng-content>\n    </clipPath>\n\n    <linearGradient [attr.id]=\"idGradient\">\n      <stop offset=\"0%\" [attr.stop-color]=\"backgroundColor\" [attr.stop-opacity]=\"backgroundOpacity\">\n        <animate\n          *ngIf=\"animate\"\n          [attr.keyTimes]=\"keyTimes\"\n          attributeName=\"offset\"\n          [attr.values]=\"animationValues[0]\"\n          [attr.dur]=\"duration\"\n          repeatCount=\"indefinite\"\n        />\n      </stop>\n\n      <stop offset=\"50%\" [attr.stop-color]=\"foregroundColor\" [attr.stop-opacity]=\"foregroundOpacity\">\n        <animate\n          *ngIf=\"animate\"\n          attributeName=\"offset\"\n          [attr.keyTimes]=\"keyTimes\"\n          [attr.values]=\"animationValues[1]\"\n          [attr.dur]=\"duration\"\n          repeatCount=\"indefinite\"\n        />\n      </stop>\n\n      <stop offset=\"100%\" [attr.stop-color]=\"backgroundColor\" [attr.stop-opacity]=\"backgroundOpacity\">\n        <animate\n          *ngIf=\"animate\"\n          attributeName=\"offset\"\n          [attr.keyTimes]=\"keyTimes\"\n          [attr.values]=\"animationValues[2]\"\n          [attr.dur]=\"duration\"\n          repeatCount=\"indefinite\"\n        />\n      </stop>\n    </linearGradient>\n  </defs>\n</svg>\n"
    }]
  }], null, {
    animate: [{
      type: Input
    }],
    baseUrl: [{
      type: Input
    }],
    speed: [{
      type: Input
    }],
    viewBox: [{
      type: Input
    }],
    gradientRatio: [{
      type: Input
    }],
    backgroundColor: [{
      type: Input
    }],
    backgroundOpacity: [{
      type: Input
    }],
    foregroundColor: [{
      type: Input
    }],
    foregroundOpacity: [{
      type: Input
    }],
    rtl: [{
      type: Input
    }],
    interval: [{
      type: Input
    }],
    style: [{
      type: Input
    }]
  });
})();
class BulletListContentLoaderComponent extends ContentLoaderComponent {}
/** @nocollapse */ /** @nocollapse */
BulletListContentLoaderComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵBulletListContentLoaderComponent_BaseFactory;
  return function BulletListContentLoaderComponent_Factory(t) {
    return (ɵBulletListContentLoaderComponent_BaseFactory || (ɵBulletListContentLoaderComponent_BaseFactory = i0.ɵɵgetInheritedFactory(BulletListContentLoaderComponent)))(t || BulletListContentLoaderComponent);
  };
})();
/** @nocollapse */ /** @nocollapse */
BulletListContentLoaderComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BulletListContentLoaderComponent,
  selectors: [["bullet-list-content-loader"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 9,
  vars: 0,
  consts: [["viewBox", "0 0 245 125"], ["cx", "10", "cy", "20", "r", "8"], ["x", "25", "y", "15", "rx", "5", "ry", "5", "width", "220", "height", "10"], ["cx", "10", "cy", "50", "r", "8"], ["x", "25", "y", "45", "rx", "5", "ry", "5", "width", "220", "height", "10"], ["cx", "10", "cy", "80", "r", "8"], ["x", "25", "y", "75", "rx", "5", "ry", "5", "width", "220", "height", "10"], ["cx", "10", "cy", "110", "r", "8"], ["x", "25", "y", "105", "rx", "5", "ry", "5", "width", "220", "height", "10"]],
  template: function BulletListContentLoaderComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "content-loader", 0);
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelement(1, "circle", 1)(2, "rect", 2)(3, "circle", 3)(4, "rect", 4)(5, "circle", 5)(6, "rect", 6)(7, "circle", 7)(8, "rect", 8);
      i0.ɵɵelementEnd();
    }
  },
  dependencies: [ContentLoaderComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BulletListContentLoaderComponent, [{
    type: Component,
    args: [{
      selector: 'bullet-list-content-loader',
      template: `
    <content-loader viewBox="0 0 245 125">
      <svg:circle cx="10" cy="20" r="8" />
      <svg:rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="10" cy="50" r="8" />
      <svg:rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="10" cy="80" r="8" />
      <svg:rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="10" cy="110" r="8" />
      <svg:rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
    </content-loader>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FacebookContentLoaderComponent extends ContentLoaderComponent {}
/** @nocollapse */ /** @nocollapse */
FacebookContentLoaderComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵFacebookContentLoaderComponent_BaseFactory;
  return function FacebookContentLoaderComponent_Factory(t) {
    return (ɵFacebookContentLoaderComponent_BaseFactory || (ɵFacebookContentLoaderComponent_BaseFactory = i0.ɵɵgetInheritedFactory(FacebookContentLoaderComponent)))(t || FacebookContentLoaderComponent);
  };
})();
/** @nocollapse */ /** @nocollapse */
FacebookContentLoaderComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FacebookContentLoaderComponent,
  selectors: [["facebook-content-loader"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 7,
  vars: 0,
  consts: [["viewBox", "0 0 476 124"], ["x", "48", "y", "8", "width", "88", "height", "6", "rx", "3"], ["x", "48", "y", "26", "width", "52", "height", "6", "rx", "3"], ["x", "0", "y", "56", "width", "410", "height", "6", "rx", "3"], ["x", "0", "y", "72", "width", "380", "height", "6", "rx", "3"], ["x", "0", "y", "88", "width", "178", "height", "6", "rx", "3"], ["cx", "20", "cy", "20", "r", "20"]],
  template: function FacebookContentLoaderComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "content-loader", 0);
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelement(1, "rect", 1)(2, "rect", 2)(3, "rect", 3)(4, "rect", 4)(5, "rect", 5)(6, "circle", 6);
      i0.ɵɵelementEnd();
    }
  },
  dependencies: [ContentLoaderComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FacebookContentLoaderComponent, [{
    type: Component,
    args: [{
      selector: 'facebook-content-loader',
      template: `
    <content-loader viewBox="0 0 476 124">
      <svg:rect x="48" y="8" width="88" height="6" rx="3" />
      <svg:rect x="48" y="26" width="52" height="6" rx="3" />
      <svg:rect x="0" y="56" width="410" height="6" rx="3" />
      <svg:rect x="0" y="72" width="380" height="6" rx="3" />
      <svg:rect x="0" y="88" width="178" height="6" rx="3" />
      <svg:circle cx="20" cy="20" r="20" />
    </content-loader>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class ListContentLoaderComponent extends ContentLoaderComponent {}
/** @nocollapse */ /** @nocollapse */
ListContentLoaderComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵListContentLoaderComponent_BaseFactory;
  return function ListContentLoaderComponent_Factory(t) {
    return (ɵListContentLoaderComponent_BaseFactory || (ɵListContentLoaderComponent_BaseFactory = i0.ɵɵgetInheritedFactory(ListContentLoaderComponent)))(t || ListContentLoaderComponent);
  };
})();
/** @nocollapse */ /** @nocollapse */
ListContentLoaderComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ListContentLoaderComponent,
  selectors: [["list-content-loader"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 7,
  vars: 0,
  consts: [["viewBox", "0 0 400 110"], ["x", "0", "y", "0", "rx", "3", "ry", "3", "width", "250", "height", "10"], ["x", "20", "y", "20", "rx", "3", "ry", "3", "width", "220", "height", "10"], ["x", "20", "y", "40", "rx", "3", "ry", "3", "width", "170", "height", "10"], ["x", "0", "y", "60", "rx", "3", "ry", "3", "width", "250", "height", "10"], ["x", "20", "y", "80", "rx", "3", "ry", "3", "width", "200", "height", "10"], ["x", "20", "y", "100", "rx", "3", "ry", "3", "width", "80", "height", "10"]],
  template: function ListContentLoaderComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "content-loader", 0);
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelement(1, "rect", 1)(2, "rect", 2)(3, "rect", 3)(4, "rect", 4)(5, "rect", 5)(6, "rect", 6);
      i0.ɵɵelementEnd();
    }
  },
  dependencies: [ContentLoaderComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListContentLoaderComponent, [{
    type: Component,
    args: [{
      selector: 'list-content-loader',
      template: `
    <content-loader viewBox="0 0 400 110">
      <svg:rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
      <svg:rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
      <svg:rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
      <svg:rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      <svg:rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
      <svg:rect x="20" y="100" rx="3" ry="3" width="80" height="10" />
    </content-loader>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class ContentLoaderModule {
  static forRoot() {
    return {
      ngModule: ContentLoaderModule
    };
  }
}
/** @nocollapse */ /** @nocollapse */
ContentLoaderModule.ɵfac = function ContentLoaderModule_Factory(t) {
  return new (t || ContentLoaderModule)();
};
/** @nocollapse */ /** @nocollapse */
ContentLoaderModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ContentLoaderModule
});
/** @nocollapse */ /** @nocollapse */
ContentLoaderModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentLoaderModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ContentLoaderComponent, FacebookContentLoaderComponent, BulletListContentLoaderComponent, ListContentLoaderComponent],
      exports: [ContentLoaderComponent, FacebookContentLoaderComponent, BulletListContentLoaderComponent, ListContentLoaderComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BulletListContentLoaderComponent, ContentLoaderComponent, ContentLoaderModule, FacebookContentLoaderComponent, ListContentLoaderComponent };
