import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Company } from '../models/company.model';

@Injectable({ providedIn: 'root' })
export class CompanyService extends AbstractService<Company> {

  getURLBase(): string {
    return '/company';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Company): void {
    delete obj?._id;
  }
  preUpdate(obj: Company): void {
  }

}
