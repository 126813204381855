import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Department } from '../models/department.model';
import { Team } from '../models/team.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DepartmentService extends AbstractService<Department> {

  getURLBase(): string {
    return '/department';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Department): void {
    delete obj?._id;
  }

  preUpdate(obj: Department): void {
  }

  createDepartmentTeams(department: Department, teams: Team[]): any {
    return this.http.post(`${this.getURLBase()}/department/teams/`, { department, teams });
  }

  updateDepartmentTeams(department: Department, teams: Team[]): any {
    return this.http.put(`${this.getURLBase()}/department/teams/`, { department, teams });
  }

  getCountAttendantsByDepartment(idsDepartment: Array<string>): Observable<unknown> {
    return super.post(`/count/attendants/by/department`, { ids: idsDepartment });
  }

}
