import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-icon-social-component',
  templateUrl: './card-icon-social.component.html'
})
export class CardIconSocialComponent {

  @Input() icon: string;
  @Input() title: string;

  constructor() { }

  ngOnInit() {

  }
}
