import { Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent } from '../../../commons/abstract.component';

@Component({
    selector: 'alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AlertModalComponent extends AbstractComponent {
    constructor(
        injector: Injector,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string },
        private _matDialogRef: MatDialogRef<AlertModalComponent>,
    ) {
        super(injector);
    }

    ngOnInit(): void {
    }

    onClose(): void {
        this._matDialogRef.close();
    }
}