<!-- <div [formGroup]="form">
  <div class="row">
    <div class="col-sm-12 col-lg-2">
      <div class="float-right">
        <button mat-icon-button color="primary" aria-label="Editar" (click)="addImage()">
          <mat-icon>edit</mat-icon>
        </button>
      </div>

      <img [src]="userImage()" />
      <input type="file" class="form-control inputfile" id="localImageUsuarioForm" (change)="onSelectImagem($event)"
        accept=".jpg, .png" #inputfile>

    </div>
    <div class="col-sm-12 col-lg-10">
      <div class="row">
        <div class="col-md-5">
          <mat-form-field>
            <mat-label>Nome</mat-label>
            <input matInput maxlength="100" formControlName="name">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput maxlength="100" formControlName="email">
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Telefone</mat-label>
            <input matInput [mask]="'(00) 00000-0000'" formControlName="phone">
          </mat-form-field>
        </div>

        <div class="col-md-5">
          <mat-form-field>
            <mat-label>CPF</mat-label>
            <input matInput [mask]="'000.000.000-00'" formControlName="tin">
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Data de nascimento</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthDate">

            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div mat-dialog-actions align="center">
      <button mat-button class="btn-add" (click)="save()">Salvar</button>
      <button mat-button class="btn-close" (click)="back()">Voltar</button>
    </div>
  </div>
</div> -->