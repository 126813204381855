import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Campaign } from '../models/campaign.model';

@Injectable({ providedIn: 'root' })
export class CampaignService extends AbstractService<Campaign> {

  getURLBase(): string {
    return '/campaign';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(campaign: Campaign): void {
    delete campaign?._id;
  }

  preUpdate(campaign: Campaign): void {
  }

  updateTags(campaign: Campaign): Observable<Campaign> {
    return super.put(`/tags/${campaign._id}`, campaign);
  }

  updateActive(campaign: Campaign): Observable<Campaign> {
    return super.put(`/${campaign._id}/activation`, null);
  }

  updateInactive(campaign: Campaign): Observable<Campaign> {
    return super.put(`/${campaign._id}/inactivation`, null);
  }

  getDataAnalytics(): Observable<unknown> {
    return super.get('/fetch/analytics');
  }

  getDataAnalyticsDash(query: any): Observable<unknown> {
    return super.get(`/fetch/analytics/dasboard?dateStart=${query.dateStart}&dateEnd=${query.dateEnd}&channel=${query.channel}`);
  }
}
