export enum UploadTypeEnum {
  USER = "users/",
  RESPONSE = "responses/",
  COMPANY = "companies/",
  CATEGORY = "categories/",
  GROUP = "groups/",
  MESSAGE = "messages/",
  CHATBOT = "chatbot/",
  CSV = "csv/",
}
