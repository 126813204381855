import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { AlertMessages } from '../../../models/enum/alert.messages';
import { NotificationTypeEnum } from '../../../models/enum/notification.type.enum';
import { Notification } from '../../../models/notification.model';
import { Alert } from './alert.model';

@Injectable({ providedIn: 'root' })
export class AlertService {

  private subject = new Subject<Alert>();
  private keepAfterNavigationChange = false;

  constructor(private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          this.keepAfterNavigationChange = false;
        } else {
          this.subject.next(<Alert>{});
        }
      }
    });
  }

  success(message: string = AlertMessages.SUCCESS, keepAfterNavigationChange = true) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(<Alert>{ type: 'success', message: message });
  }

  warning(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(<Alert>{ type: 'warning', message: message });
  }

  error(message: string = AlertMessages.ERROR, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(<Alert>{ type: 'error', message: message });
  }

  info(title: string, message?: string, keepAfterNavigationChange = true) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(<Alert>{ type: 'info', title, message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  tinyAlert() {
    Swal.fire('Hey there!');
  }

  modal(title: string, message: string, type: SweetAlertIcon) {
    Swal.fire(title, message, type);
  }

  toast(title: string, message: string, icon: SweetAlertIcon) {
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
      position: 'top-end',
      backdrop: false,
      timer: 3000,
      toast: true,
      // showConfirmButton: false,
      // showCancelButton: true,
      // confirmButtonText: 'Yes, go ahead.',
      // cancelButtonText: 'No, let me think',
    }).then((result) => {
      // if (result.value) {
      //   Swal.fire('Removed!', 'Product removed successfully.', 'success');
      // } else if (result.dismiss === Swal.DismissReason.cancel) {
      //   Swal.fire('Cancelled', 'Product still in our database.)', 'error');
      // }
    });
  }

  toasNotificationtWarningWork(notification: Notification, icon: SweetAlertIcon) {

    if (notification.type === NotificationTypeEnum.WARNING_WORD) {

      Swal.fire({
        title: notification.title,
        text: notification.description,
        icon: icon,
        position: 'top-end',
        backdrop: false,
        timer: 7000,
        toast: true,
        showConfirmButton: true,
        confirmButtonText: 'Ir para o atendimento',
      }).then((result) => {
        if (result.value) {
          //   Swal.fire('Removed!', 'Product removed successfully.', 'success');
          // } else if (result.dismiss === Swal.DismissReason.cancel) {
          //   Swal.fire('Cancelled', 'Product still in our database.)', 'error');
        }
      });
    }
  }
}
