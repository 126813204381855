<div class="w-full flex justify-center flex-col">
  <h1 class="font-black text-[20px] text-black">Configurações de Atendimento</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Selecione um canal para ver as configurações do seu atendimento</h6>
</div>

@if (channel) {
<div class="w-full overflow-hidden h-full">
  <form [formGroup]="formGroup" class="flex w-[33%]">
    <div
      class="bg-white p-3 cursor-pointer shadow rounded-lg md:w-[33%] sm:w-[100%] mobile:w-[100%] h-auto border-2 border-gray-100">
      <!-- Div principal clicável para abrir o modal -->
      <div>
        <div class="flex w-100 justify-between items-center pb-2 border-b-2 border-gray-100">
          <div class="flex gap-3 flex-1">
            Módulo CSAT
          </div>
          <div class="inline-block text-left">
            <mat-slide-toggle color="primary" formControlName="activeCSATModule">
              <div class="flex flex-col items-start">
                <h1 class="text-base">Habilitar</h1>
              </div>
            </mat-slide-toggle>
          </div>
        </div>
        @if(isCSATModuleActive()) {
        <div class="flex flex-col h-full justify-start items-start mt-2 gap-2" (click)="editCSAT()">
          <div>
            <label class="text-sm text-gray-400">Pergunta da Avaliação:</label>
            <h5 class="font-semibold text-sm">{{ settingsModule?.evaluationQuestionCSAT ?? '-' }}</h5>
          </div>
          <div>
            <label class="text-sm text-gray-400">Opções de Resposta:</label>
            <h5 class="font-semibold text-sm">{{ settingsModule?.note1CSAT ?? '-' }}</h5>
            <h5 class="font-semibold text-sm">{{ settingsModule?.note2CSAT ?? '-' }}</h5>
            <h5 class="font-semibold text-sm">{{ settingsModule?.note3CSAT ?? '-' }}</h5>
            <h5 class="font-semibold text-sm">{{ settingsModule?.note4CSAT ?? '-' }}</h5>
            <h5 class="font-semibold text-sm">{{ settingsModule?.note5CSAT ?? '-' }}</h5>
          </div>
          <div>
            <label class="text-sm text-gray-400">Mensagem em caso de avaliação igual a 5:</label>
            <h5 class="font-semibold text-sm" [innerHTML]="settingsModule?.messageSendCSAT ?? '-' "></h5>
          </div>
        </div>
        }
      </div>
    </div>
  </form>
</div>
<div class="flex flex-1 border-t-2 border-gray-200 justify-end items-center w-full gap-4 mt-3 mb-4">
  <button
    class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
    cdkFocusInitial (click)="onConfirmClick()" *ngIf="isAdmin()">Salvar</button>
</div>
}