import { Pipe, PipeTransform } from '@angular/core';
import { TagTypeEnum } from '../../models/enum/tag.type';

@Pipe({
  name: 'tagType'
})
export class TagTypePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    let tag = '';
    switch (value) {
      case TagTypeEnum.CAMPAIGN:
        tag = 'Campanha';
        break;
      case TagTypeEnum.PROTOCOL:
        tag = 'Protocolo';
        break;
      case TagTypeEnum.CONTACT:
        tag = 'Contato';
        break;
      case TagTypeEnum.INTEREST:
          tag = 'Interesse';
          break;
      default:
        tag = '';
        break;
    }
    return tag;
  }
}
