import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../../services/loading.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'loading-component',
  templateUrl: './loading.component.html'
})
export class LoadingComponent implements OnInit {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.loading$.subscribe((value) => {
      this.isLoading$.next(false);
    });
  }
}
