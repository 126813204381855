import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cnpjType'
})
export class CnpjTypePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    value = value.replace(/\D/g, '');

    while (value.length < 14) {
      value = '0' + value;
    }

    return `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(5, 8)}/${value.substring(8, 12)}-${value.substring(12)}`;
  }
}
