<div class="w-full">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">Alterar Módulo CSAT</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados do módulo CSAT</h6>

  <form [formGroup]="formGroup" style="max-height: 465px; overflow-y: auto; overflow-x: hidden; padding: 5px;">
  <div class="flex flex-col flex-1 my-2">
    <label class="font-medium text-black">Pergunta da Avaliação</label>
    <input class="input-field" maxlength="100" formControlName="evaluationQuestionCSAT"
      placeholder="Pergunta de avaliação...">
    <field-error-component [field]="'evaluationQuestionCSAT'" [label]="'Pergunta da Avaliação'" [formGroup]="formGroup">
    </field-error-component>
  </div>
  <div class="flex flex-col flex-1 mb-2">
    <label class="font-medium text-black mb-2"><b>Opções de Resposta</b></label>
    <label class="font-medium text-black">Opção Nota 1</label>
    <input class="input-field" maxlength="100" formControlName="note1CSAT">
    <field-error-component [field]="'note1CSAT'" [label]="'Opção Nota 1'" [formGroup]="formGroup">
    </field-error-component>
  </div>
  <div class="flex flex-col flex-1 mb-2">
    <label class="font-medium text-black">Opção Nota 2</label>
    <input class="input-field" maxlength="100" formControlName="note2CSAT">
    <field-error-component [field]="'note2CSAT'" [label]="'Opção Nota 2'" [formGroup]="formGroup">
    </field-error-component>
  </div>
  <div class="flex flex-col flex-1 mb-2">
    <label class="font-medium text-black">Opção Nota 3</label>
    <input class="input-field" maxlength="100" formControlName="note3CSAT">
    <field-error-component [field]="'note3CSAT'" [label]="'Opção Nota 3'" [formGroup]="formGroup">
    </field-error-component>
  </div>
  <div class="flex flex-col flex-1 mb-2">
    <label class="font-medium text-black">Opção Nota 4</label>
    <input class="input-field" maxlength="100" formControlName="note4CSAT">
    <field-error-component [field]="'note4CSAT'" [label]="'Opção Nota 4'" [formGroup]="formGroup">
    </field-error-component>
  </div>
  <div class="flex flex-col flex-1 mb-2">
    <label class="font-medium text-black">Opção Nota 5</label>
    <input class="input-field" maxlength="100" formControlName="note5CSAT">
    <field-error-component [field]="'note5CSAT'" [label]="'Opção Nota 5'" [formGroup]="formGroup">
    </field-error-component>
  </div>
  <div class="flex flex-col flex-1 mb-2">
    <label class="font-medium text-black">Mensagem em caso de avaliação igual a 5</label>
    <div class="NgxEditor__Wrapper">
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
      <ngx-editor [editor]="editor" formControlName="messageSendCSAT" height="500px"></ngx-editor>
    </div>
    <field-error-component [field]="'messageSendCSAT'" [label]="'Mensagem em caso de avaliação igual a 5'"
      [formGroup]="formGroup">
    </field-error-component>
  </div>
  </form>
</div>
<div class="flex justify-between items-center w-full gap-2 px-0 mt-4">
  <button
    class="h-[45px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
    (click)="close()">Cancelar</button>
  <button
    class="h-[45px] px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[50%]"
    (click)="save()">
    {{ !loading ? 'Salvar' : '' }}
    <mat-spinner class="mr-4" [diameter]="25" [color]="'accent'" mode="indeterminate" *ngIf="loading"></mat-spinner>
  </button>
</div>