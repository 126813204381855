import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { WebhookIntegrations } from '../models/webhook-integrations.model';
import { Webhook } from '../models/webhook.model';

@Injectable({ providedIn: 'root' })
export class WebhookIntegrationsService extends AbstractService<WebhookIntegrations> {

  getURLBase(): string {
    return '/webhook-integrations';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: WebhookIntegrations): void {
    delete obj?._id;
  }
  preUpdate(obj: WebhookIntegrations): void {
  }

  getEventsByUUID(uuid: string): Observable<Array<Webhook>> {
    return super.get(`/events/by/uuid/${uuid}/`);
  }

}  