export enum ImportationTypeEnum {
  CONTACT = "CONTACT",
}

export enum ImportationStatusEnum {
  WAITING = "WAITING",
  RUNNING = "RUNNING",
  DONE = "DONE",
}

export enum ImportationStatusTranslateEnum {
  WAITING = "Aguadando",
  RUNNING = "Em Execução",
  DONE = "Finalizado",
}