import { Injectable, Injector } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs';
import { ENV } from '../../../commons/injection.token';
import { StorageInterface } from '../storage.interface';
import { UploadTypeEnum } from '../upload.type.enum';

@Injectable({ providedIn: 'root' })
export class FirebaseService implements StorageInterface {

  private storageBucket;

  constructor(injector: Injector, private storage: AngularFireStorage) {
    const env = injector.get(ENV);
    this.storageBucket = env['firebase']['storageBucket'];
  }

  /**
   * 
   * https://www.bezkoder.com/angular-16-firebase-storage/
   * 
   * @param file 
   * @param filename 
   * @param uploadTypeEnum 
   * @returns 
   */
  uploadFile(file: File, filename: string, uploadTypeEnum: UploadTypeEnum): Promise<any> {
    try {
      const filePath = uploadTypeEnum.toString() + filename;
      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, file);

      return new Promise<any>((resolve, reject) => {
        return uploadTask.snapshotChanges().pipe(
          finalize(() => {
            return storageRef.getDownloadURL().subscribe((url) => {
              resolve({ url });
            });
          })
        ).subscribe({
          error: (err) => {
            console.warn('Ocorreu algum erro ao enviar o arquivo para o Firebase Google')
            console.error(JSON.stringify(err));
            reject(err);
          }
        })
      });
    } catch (error) {
      console.error(error);
    }
    return Promise.resolve(null);
  }

  /**
   * 
   * https://www.bezkoder.com/angular-16-firebase-storage/
   * 
   * @param storageUrl 
   */
  deleteFile(storageUrl: string) {
    return new Promise<any>((resolve, reject) => {
      try {
        if (storageUrl.indexOf(this.storageBucket) !== -1) {
          const deleteTask = this.storage.refFromURL(storageUrl);
          resolve(deleteTask.delete());
        } else {
          resolve(storageUrl);
        }
      } catch (error) {
        console.error(error);
        resolve(error);
      }
    });
  }

}

