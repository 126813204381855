<div mat-dialog-content class="p-1">
  <div class="w-full flex justify-center flex-col">
    <div class="flex w-100 flex-1 justify-start mb-4">
      <div
        class="flex justify-center items-center w-[50px] h-[50px] rounded-full bg-green-100 border-green-50 border-8">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#039855" />
        </svg>
      </div>
    </div>
    <div>
      <h1 class="font-black text-[20px] text-black">{{isNew? 'Adicionar' : 'Alterar'}} Grupo</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie um novo grupo</h6>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div class="flex w-full flex-col">
      <div class="flex flex-col flex-1">
        <label class="text-black mb-2">Nome</label>
        <input formControlName="name" matInput
          class="p-2.5 mt-1 mb-2 border-2 border-[#BDBDBD] rounded-lg bg-transparent text-[inherit] font-[inherit] focus:border-[#BDBDBD] focus:outline-none"
          maxlength="100" placeholder="Digitar...">
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions align="center" class="flex justify-between items-center w-full gap-2 px-0 mt-3">
  <button
    class="h-[45px] flex flex-1 justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
    (click)="close()">Cancelar</button>
  <button
    class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[47%]"
    (click)="save()">{{isNew? 'Adicionar' : 'Alterar'}}</button>
</div>
