<content-loader [viewBox]="'0 0 1000 600'" [speed]="2">
  <!-- Cabeçalhos da tabela -->
  <svg:rect x="10" y="10" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="180" y="10" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="350" y="10" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="520" y="10" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="690" y="10" rx="4" ry="4" width="150" height="20" />

  <!-- Simulando 8 linhas da tabela -->
  <!-- Primeira linha -->
  <svg:rect x="10" y="50" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="180" y="50" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="350" y="50" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="520" y="50" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="690" y="50" rx="4" ry="4" width="150" height="20" />

  <!-- Segunda linha -->
  <svg:rect x="10" y="90" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="180" y="90" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="350" y="90" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="520" y="90" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="690" y="90" rx="4" ry="4" width="150" height="20" />

  <!-- Terceira linha -->
  <svg:rect x="10" y="130" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="180" y="130" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="350" y="130" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="520" y="130" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="690" y="130" rx="4" ry="4" width="150" height="20" />

  <!-- Quarta linha -->
  <svg:rect x="10" y="170" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="180" y="170" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="350" y="170" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="520" y="170" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="690" y="170" rx="4" ry="4" width="150" height="20" />

  <!-- Quinta linha -->
  <svg:rect x="10" y="210" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="180" y="210" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="350" y="210" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="520" y="210" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="690" y="210" rx="4" ry="4" width="150" height="20" />

  <!-- Sexta linha -->
  <svg:rect x="10" y="250" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="180" y="250" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="350" y="250" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="520" y="250" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="690" y="250" rx="4" ry="4" width="150" height="20" />

  <!-- Sétima linha -->
  <svg:rect x="10" y="290" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="180" y="290" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="350" y="290" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="520" y="290" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="690" y="290" rx="4" ry="4" width="150" height="20" />

  <!-- Oitava linha -->
  <svg:rect x="10" y="330" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="180" y="330" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="350" y="330" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="520" y="330" rx="4" ry="4" width="150" height="20" />
  <svg:rect x="690" y="330" rx="4" ry="4" width="150" height="20" />
</content-loader>