import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-graphic-small-component',
  templateUrl: './card-graphic-small.component.html'
})
export class CardGraphicSmallComponent {

  @Input() total?: string;
  @Input() label?: string;
  @Input() data?: string;
  @Input() graphicType?: string; //tipar tipos de graficos


  // options for the chart
  showXAxis = true;
  showYAxis = false;
  gradient = true;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = '';
  showYAxisLabel = false;
  yAxisLabel = '';
  timeline = true;
  doughnut = true;
  colorScheme = {
    domain: ['#9370DB', '#87CEFA', '#FA8072', '#FF7F50', '#90EE90']
  };
  //pie
  showLabels = true;

  public single = [
    {
      "name": "Seg",
      "value": 2243772
    },
    {
      "name": "Ter",
      "value": 1126000
    },
    {
      "name": "Qua",
      "value": 296215
    },
    {
      "name": "Qui",
      "value": 257363
    },
    {
      "name": "Sex",
      "value": 196750
    },

  ];

  constructor() { }

  ngOnInit() {



  }

  onSelect(string){

  }
}
