import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Importation } from '../models/importation.model';
import { Team } from '../models/team.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImportationService extends AbstractService<Importation> {

  getURLBase(): string {
    return '/importation';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Importation): void {
    delete obj?._id;
  }

  preUpdate(obj: Importation): void {
  }

}
