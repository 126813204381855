import { Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AbstractComponent } from '../../../../commons/abstract.component';
import { UtilHelper } from '../../../../helpers/util.helper';
import { Company } from '../../../../models/company.model';
import { User } from '../../../../models/user.model';
import { FirebaseService } from '../../../../services/storage/firebase/firebase.service';
import { UploadTypeEnum } from '../../../../services/storage/upload.type.enum';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrl: './user-edit.component.scss'
})
export class UserEditComponent extends AbstractComponent {

  @Output() onSave: EventEmitter<User> = new EventEmitter<User>();
  @Input() idCompany: string;

  @ViewChild('inputfile', { static: false }) inputfile: ElementRef;

  form: FormGroup;
  user: User;
  file: File;
  bytes: string;

  constructor(
    injector: Injector,
    public firebaseService: FirebaseService,
    public userService: UserService
  ) {
    super(injector);
    this.createForm();
  }

  /***/
  async ngOnInit() {
    const userId = this.getParam("id");

    if (userId) {
      this.userService.getById(userId).subscribe(user => {
        this.user = user;
        if (this.user) {
          this.form.patchValue({ ...this.user });
        }
      })
    }
  }

  /***/
  private createForm(): void {
    this.form = this.formBuilder.group({
      _id: [null, []],
      name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
      email: [null, [Validators.required, Validators.pattern(this.emailRegex)]],
      phone: [null, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      tin: [null, [Validators.required]],
      birthDate: [null, [Validators.required]],
      image: [null, []],
      //department: [null, [Validators.required]],
    });
  }

  /***/
  addImage() {
    this.inputfile.nativeElement.click();
  }

  /***/
  back(): void {
    history.back();
  }

  userImage() {
    return this.form && this.form.get('image') && this.form.get('image').value;
  }

  save() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const user = { ...this.form.value };
    if (this.user) {
      user._id = this.user._id;
      user.company = this.user.company;
    } else {
      user.company = { _id: this.idCompany } as Company;
    }
    this.onSave.emit(user);
  }


  openFileBrowser(event: any) {
    event.preventDefault();
    const element: HTMLElement = document.getElementById('localImageUsuarioForm') as HTMLElement;
    element.click();
  }

  onSelectImagem(event) {
    const target = event.target;
    const files: FileList = target.files;

    // if (files[0].size > 1000000) { // 1 MB
    //   this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 1 MB.');
    //   return;
    // }

    if (!(files[0].type === 'image/jpeg' || files[0].type === 'image/png')) {
      this.alertService.warning('Imagem inválida. O arquivo de imagem deve estar no formato JPG ou PNG.');
      return;
    }

    this.file = files[0];

    let reader = new FileReader();
    reader.onload = async file => {
      if (this.file) {
        this.loading = true;
        const contents = file.target;
        const base64 = UtilHelper.arrayBufferToBase64(contents.result);

        const dateMilisecond = new Date().getTime();
        const filename: string = dateMilisecond.toString();
        this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.USER).then((snapshot: { url: string }) => {
          this.loading = false;
          this.form.patchValue({ image: snapshot.url });
        });
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

}
