import { Component, EventEmitter, Inject, Injector, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime } from 'rxjs';
import { AbstractComponent } from '../../../../commons/abstract.component';
import { UtilHelper } from '../../../../helpers/util.helper';
import { ContactGroup } from '../../../../models/contact-group.model';
import { Contact } from '../../../../models/contact.model';
import { CountryModel } from '../../../../models/country.model';
import { CustomField } from '../../../../models/custom-field.model';
import { Department } from '../../../../models/department.model';
import { ContactGenderEnum } from '../../../../models/enum/contact-gender.type.enum';
import { Tag } from '../../../../models/tag.model';
import { User } from '../../../../models/user.model';
import { ContactGroupService } from '../../../../services/contact-group.service';
import { ContactService } from '../../../../services/contact.service';
import { CustomFieldService } from '../../../../services/custom-field.service';
import { DepartmentService } from '../../../../services/department.service';
import { TagService } from '../../../../services/tag.service';

@Component({
  selector: 'contact-edit-component',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactEditComponent extends AbstractComponent {
  filterControlGroup: FormControl = new FormControl('');
  filterControlTags: FormControl = new FormControl('');
  filterCustomField: FormControl = new FormControl('');

  @Input() user: User;
  @Output() onDelete: EventEmitter<User> = new EventEmitter<User>();
  @Output() onEdit: EventEmitter<User> = new EventEmitter<User>();

  contact: Contact;
  currentUser: any;
  listContactGroup: Array<ContactGroup> = new Array<ContactGroup>();
  allGroups: Array<ContactGroup> = new Array<ContactGroup>();

  departmentsList: Department[];

  countries: CountryModel[] = [];
  selectedCountryCode!: string;
  phoneMask!: string;

  listTags: Array<Tag> = new Array<Tag>();
  allTags: Array<Tag> = new Array<Tag>();

  listGenders: Array<ContactGenderEnum> =
    new Array<ContactGenderEnum>(ContactGenderEnum.MALE, ContactGenderEnum.FEMALE, ContactGenderEnum.OTHER);

  listFieldsCustom: Array<CustomField> = new Array<CustomField>();
  allFieldsCustom: Array<CustomField> = new Array<CustomField>();

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ContactEditComponent>,
    public contactService: ContactService,
    public contactGrupoService: ContactGroupService,
    public departmentService: DepartmentService,
    private tagService: TagService,
    private fieldsCustomService: CustomFieldService,
    @Inject(MAT_DIALOG_DATA) public data: Contact,
  ) {
    super(injector);
    this.contact = {} as Contact;
    this.createForm();

    this.getListGroups();
    this.fetchFilterGroups();

    this.loadDepartments();

    this.getListTags();
    this.fetchFilterTags();
  }

  ngOnInit() {
    this.currentUser = this.getCurrentUserUser();
    this.countries = UtilHelper.getCountries();

    this.isNew = (!!this.data ? false : true);

    if (!this.isNew) {
      this.contact = { ...this.data };

      this.formGroup.patchValue(this.contact);

      this.formGroup.get('groups')?.setValue(this.contact?.groups?.map((group: ContactGroup) => group.name ? group._id : group));

      this.formGroup.get('tags')?.setValue(this.contact?.tags?.map((tag) => tag.title ? tag._id : tag));

      let phoneNumber = this.formGroup.get('phone')?.value;
      let country = UtilHelper.getCountryByPhoneNumber(phoneNumber);

      if (country) {
        this.selectedCountryCode = country.code;

        let phoneNumberWithoutCountryCode = phoneNumber.replace(country.code.replace('+', ''), '');

        this.formGroup.patchValue({
          ...this.contact,
          phone: phoneNumberWithoutCountryCode,
          country: country.code,
        });
      }

      this.formGroup.patchValue({
        ...this.contact,
        selectedFields: this.data?.customFields?.map(field => field._id),
      });
      this.populateCustomFields(this.data?.customFields);
    } else {
      this.selectedCountryCode = this.countries[0].code;
    }

    this.phoneMask = this.countries.find(country => country.code === this.selectedCountryCode)?.mask || '';
  }

  onCountryChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCountryCode = selectElement.value;

    const selectedCountry = this.countries.find(country => country.code === this.selectedCountryCode);
    this.phoneMask = selectedCountry ? selectedCountry.mask : '';
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
      image: [null],
      phone: [null, Validators.required],
      email: [null, [Validators.pattern(this.emailRegex)]],
      zip: [null],
      address: [null],
      city: [null],
      state: [null],
      groups: [null],
      country: [null],
      tags: [null],
      tin: [null],
      contactCompany: [null],
      contactPosition: [null],
      gender: [ContactGenderEnum.OTHER],
      birthDate: [null],
      customFields: new FormArray([]),
      selectedFields: [null],
    });

    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.contact, value);
    });

    this.getListFieldsCustom();
    this.fetchFilterCustomFields();
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    }
    this.loading = true;
    this.setGroups();
    this.setPhone();
    this.setTags();

    if (this.isNew) {
      this.contactService.create(this.contact).subscribe({
        next: (value) => {
          this.loading = false
          this.dialogRef.close(value);
          this.alertService.success('Contato salvo com sucesso.');
        },
      });
    } else {
      this.contactService.update(this.contact._id, this.contact).subscribe({
        next: (value) => {
          this.loading = false
          this.dialogRef.close(value);
          this.alertService.success('Contato atualizado com sucesso.');
        },
      });
    }
  }

  private setTags(): void {
    const tags = this.formGroup.get('tags')?.value;
    this.contact.tags = [];
    tags?.forEach((idTag: string) => {
      this.contact.tags.push(<Tag>{ _id: idTag });
    });
  }

  private setPhone(): void {
    const phone = this.formGroup.get('phone')?.value;
    const fullPhoneNumber = `${this.selectedCountryCode}${phone}`.replace('+', '');
    this.contact.phone = fullPhoneNumber;
  }

  private setGroups(): void {
    const groups = this.formGroup.get('groups').value;
    this.contact.groups = [];
    groups?.forEach((idGroup: string) => {
      this.contact.groups.push({ _id: idGroup } as ContactGroup);
    });
  }

  private loadDepartments() {
    this.departmentService.getList().subscribe({
      next: (departments: Array<Department>) => {
        this.departmentsList = departments;
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  keyPressOnlyNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onCep(cep: string): void {
    if (cep.length === 8) {
      this.getCep(cep);
    }
  }

  private getCep(cep: string) {
    return this.contactService.searchCep(cep).subscribe({
      next: (res) => {
        this.formGroup.patchValue({
          address: res.street,
          city: res.city,
          state: res.state,
        });
      },
      error: (err) => this.alertService.error(err),
    });
  }

  private getListTags(): void {
    this.tagService.getList().subscribe({
      next: (value) => {
        this.allTags = value;
        this.listTags = value;
      },
      error: () => this.alertService.error(),
    });
  }

  private getListFieldsCustom(): void {
    this.fieldsCustomService.getList().subscribe({
      next: (list: Array<CustomField>) => {
        this.allFieldsCustom = list;
        this.listFieldsCustom = list;
      },
      error: () => this.alertService.error(),
    });
  }

  updateCustomFields(selectedFieldIds: Array<string>): void {
    const customFieldsArray = this.formGroup.get('customFields') as FormArray;

    for (let i = customFieldsArray.length - 1; i >= 0; i--) {
      const fieldControl = customFieldsArray.at(i);
      const fieldId = fieldControl.get('_id')?.value;

      if (!selectedFieldIds.includes(fieldId)) {
        customFieldsArray.removeAt(i);
      }
    }

    selectedFieldIds.forEach((fieldId) => {
      const existingField = customFieldsArray.controls.find(control => control.get('_id')?.value === fieldId);

      if (!existingField) {
        const field = this.listFieldsCustom.find((item) => item._id === fieldId);
        if (field) {
          customFieldsArray.push(this.createCustomFieldGroup(field));
        }
      }
    });
  }

  private populateCustomFields(customFields: Array<{ _id: string, name: string, description: string, value: unknown }>): void {
    if (!!customFields) {
      const customFieldsArray = this.formGroup.get('customFields') as FormArray;
      customFieldsArray.clear();

      customFields.forEach(field => {
        customFieldsArray.push(this.formBuilder.group({
          _id: [field._id],
          name: [field.name],
          description: [field.description],
          value: [field.value],
        }));
      });
    }
  }

  private createCustomFieldGroup(field: CustomField): FormGroup {
    return new FormGroup({
      _id: new FormControl(field._id),
      name: new FormControl(field.name),
      description: new FormControl(field.description),
      value: new FormControl('', Validators.required),
    });
  }

  get customFields() {
    return this.formGroup.controls['customFields'] as FormArray;
  }

  getFieldName(fieldId: string): string {
    const field = this.listFieldsCustom.find(item => item._id === fieldId);
    return field ? field.name : '';
  }

  getFieldType(fieldId: string): string {
    const field = this.listFieldsCustom.find(item => item._id === fieldId);
    return field ? field.type : '';
  }

  private getListGroups(): void {
    this.contactGrupoService.getList().subscribe({
      next: value => {
        this.listContactGroup = value;
        this.allGroups = value;
      },
      error: err => this.alertService.error('Ops! Ocorreu um erro ao tentar buscar por todas as categorias. Tente novamente mais tarde.'),
    });
  }

  private fetchFilterGroups(): void {
    this.filterControlGroup.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('groups')?.value || [];

      this.allGroups = this.listContactGroup.filter(group =>
        group.name.toLowerCase().includes(value.toLowerCase()) &&
        !currentValue.includes(group._id)
      );

      currentValue.forEach(groupId => {
        const existingGroup = this.listContactGroup.find(group => group._id === groupId);
        if (existingGroup) {
          this.allGroups.push(existingGroup);
        }
      });
    });
  }

  onFocusGroup(): void {
    this.allGroups = this.listContactGroup;
  }

  private fetchFilterTags(): void {
    this.filterControlTags.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('tags')?.value || [];

      this.allTags = this.listTags.filter(tag =>
        tag.title.toLowerCase().includes(value.toLowerCase()) &&
        !currentValue.includes(tag._id)
      );

      currentValue.forEach(tagId => {
        const existingTags = this.listTags.find(tag => tag._id === tagId);
        if (existingTags) {
          this.allTags.push(existingTags);
        }
      });
    });
  }

  onFocusTags(): void {
    this.allTags = this.listTags;
  }

  private fetchFilterCustomFields(): void {
    this.filterCustomField.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('selectedFields')?.value || [];

      this.allFieldsCustom = this.listFieldsCustom.filter(field =>
        field.name.toLowerCase().includes(value.toLowerCase()) &&
        !currentValue.includes(field._id)
      );

      currentValue.forEach(fieldId => {
        const existingFields = this.listFieldsCustom.find(field => field._id === fieldId);
        if (existingFields) {
          this.allFieldsCustom.push(existingFields);
        }
      });
    });
  }

  onFocusCustomFields(): void {
    this.allFieldsCustom = this.listFieldsCustom;
  }
}
