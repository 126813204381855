import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CONSTANTS, Constants, ENV, Environment } from './injection.token';

@Injectable()
export class AuthWebGuard implements CanActivate {

  constructor(
    @Inject(ENV) private env: Environment,
    @Inject(CONSTANTS) private constants: Constants
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (localStorage.getItem(this.constants['currentUser'])) {
      return true;
    }
    window.location.href = `${this.env['trendWebAppUrl']}/login`;
    return false;
  }

}
