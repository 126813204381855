
import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs'; 
import { Importation } from '../models/importation.model';
import { DateUtil } from '../helpers/date.util';
import { ImportationStatusTranslateEnum } from '../../public-api';

@Injectable()
export class ExportService {
  async generateExcel(data: any[]): Promise<any> {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Relatório-Performance');

    worksheet.columns = [
      { header: 'Nome Canal', key: 'channel', width: 30 },
      { header: 'Departamento', key: 'department', width: 30 },
      { header: 'Atendante', key: 'user', width: 40 },
      { header: 'Atendimento (esse mês)', key: 'totalAttendanceCurrent', width: 30 },
      { header: 'Atendimento (mês anterior)', key: 'totalAttendancePrevious', width: 30 },
    ];

    data.forEach((item) => {
        worksheet.addRow({
          channel: item.channel,
          department: item.department,
          user: item.user,
          totalAttendanceCurrent: item.totalAttendanceCurrent,
          totalAttendancePrevious: item.totalAttendancePrevious,
        });
      });

      return await workbook.xlsx.writeBuffer();
  }

  generateExcelImportation(data: Importation[]): Promise<any> {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Relatório-Importação');

    worksheet.columns = [
      { header: 'Criado em', key: 'createdAt', width: 30 },
      { header: 'Arquivo', key: 'filename', width: 30 },
      { header: 'Situação', key: 'status', width: 30 },
      { header: 'Total de Contatos', key: 'totalSend', width: 30 },
      { header: 'Novos Contatos', key: 'totalInserted', width: 30 },
      { header: 'Contatos Atualizados', key: 'totalUpdated', width: 30 },
      { header: 'Tags Criadas', key: 'totalCreatedTag', width: 30 },
      { header: 'Contatos com erros', key: 'totalError', width: 30 },
    ];

    data.forEach((item) => {
        worksheet.addRow({
          createdAt: DateUtil.toFormart(item.createdAt, 'dd/MM/yyyy HH:mm:ss'),
          filename: item.filename,
          status: ImportationStatusTranslateEnum[item.status],
          totalSend: item.metadata?.totalSend,
          totalInserted: item.metadata?.totalInserted,
          totalUpdated: item.metadata?.totalUpdated,
          totalCreatedTag: item.metadata?.totalCreatedTag,
          totalError: item.metadata?.totalError,
        });
      });

      return workbook.xlsx.writeBuffer();
  }
}
