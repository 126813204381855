import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Response } from '../models/response.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ResponseService extends AbstractService<Response> {

  getURLBase(): string {
    return '/response';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Response): void {
    delete obj?._id;
  }

  preUpdate(obj: Response): void {
  }

  updateAll(idChannel: string, objs: Array<Response>): Observable<Array<Response>> {
    return super.put(`/update-all/${idChannel}`, objs);
  }
}
