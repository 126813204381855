import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Notification } from '../models/notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationService extends AbstractService<Notification> {

  getURLBase(): string {
    return '/notification';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Notification): void {
    delete obj?._id;
  }
  preUpdate(obj: Notification): void {
  }

}  