
export class WaTemplateComponentExample {
  body_text: string[];
}

export class WaTemplateComponent {
  type: string;
  format: 'IMAGE' | 'TEXT';
  text: string;
  example: WaTemplateComponentExample;
}

export class WaButtonComponent {
  type: 'QUICK_REPLY' | '';
  text: string;
}

export class WaHeaderComponent {
  type: string = 'HEADER';
  format: 'IMAGE' | 'TEXT';
  example: WaTemplateComponentExample;
  text: string;
}

export class WaBodyComponent {
  type?: string = 'BODY';
  example?: WaTemplateComponentExample;
  text?: string;
}

export class WaFooterComponent {
  type: string = 'FOOTER';
  text: string;
}

export class WaButtonsComponent {
  type: string = 'BUTTONS';
  buttons: WaButtonComponent[];
}

export class WaTemplateResponse {
  name: string;
  components: any[];
  language: string;
  status: string;
  category: string;
  id: string;

  headerComponent?: WaHeaderComponent;
  bodyComponent?: WaBodyComponent;
  footerComponent?: WaFooterComponent;
  buttonsComponent?: WaButtonsComponent;

  public extractComponents() {
    this.headerComponent = this.components.find(c => c.type === 'HEADER');
    this.bodyComponent = this.components.find(c => c.type === 'BODY');
    this.footerComponent = this.components.find(c => c.type === 'FOOTER');
    this.buttonsComponent = this.components.find(c => c.type === 'BUTTONS');
  }
}

// **************************************************
// ***** payload HEADER com imagem
// {
// 	"name": "envio_catalogo",
// 	"components": [
// 		{
// 			"type": "HEADER",
// 			"format": "IMAGE",
// 			"example": {
// 				"header_handle": [
// 					"https://scontent.whatsapp.net/v/t61.29466-34/324044779_467608389216646_498090817613278463_n.png?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=wf7M6rf1XMAQ7kNvgGiZUrl&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&oh=01_Q5AaIERTpOskPv_dh4DVIYswOrIgO4wC71tN56EMZulmRmky&oe=66C6FC0D"
// 				]
// 			}
// 		},
// 		{
// 			"type": "BODY",
// 			"text": "Olá {{1}},\n\nEm anexo segue o nosso catálogo de produtos da Trend.",
// 			"example": {
// 				"body_text": [
// 					[
// 						"Seles"
// 					]
// 				]
// 			}
// 		},
// 		{
// 			"type": "FOOTER",
// 			"text": "https://trend.com.br"
// 		},
// 		{
// 			"type": "BUTTONS",
// 			"buttons": [
// 				{
// 					"type": "QUICK_REPLY",
// 					"text": "Recebi Catalogo"
// 				}
// 			]
// 		}
// 	],
// 	"language": "pt_BR",
// 	"status": "APPROVED",
// 	"category": "MARKETING",
// 	"sub_category": "CUSTOM",
// 	"id": "467608385883313"
// }


// **************************************************
// ***** payload HEADER Text
//   {
//     "name": "teste_hello",
//     "components": [
//         {
//             "type": "HEADER",
//             "format": "TEXT",
//             "text": "Atendimento"
//         },
//         {
//             "type": "BODY",
//             "text": "Meu texto vai aqui {{1}}, ok? Isso é pra gente não esquecer!",
//             "example": {
//                 "body_text": [
//                     [
//                         "Vinicius"
//                     ]
//                 ]
//             }
//         },
//         {
//             "type": "FOOTER",
//             "text": "@hello"
//         }
//     ],
//     "language": "pt_BR",
//     "status": "APPROVED",
//     "category": "MARKETING",
//     "id": "971840504245314"
// }
