import { HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONSTANTS, Constants } from './injection.token';

@Injectable({ providedIn: 'root' })
export class JwtService implements HttpInterceptor {

  constructor(@Inject(CONSTANTS) private constants: Constants) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    let headers = {};
    let currentUser = JSON.parse(localStorage.getItem(this.constants['currentUser']));
    if (currentUser && currentUser.token) {
      headers = {
        'Authorization': 'Bearer ' + currentUser.token,
      }
    }
    const clone = req.clone({ setHeaders: headers });
    return next.handle(clone);
  }
}