import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AbstractComponent } from '../../../commons/abstract.component';
import { Channel } from '../../../models/channel.model';
import { SettingsModule } from '../../../models/settings-module.model';
import { SettingsModuleService } from '../../../services/settings-module.service';
import { Validators } from '@angular/forms';
import { Editor, Toolbar } from 'ngx-editor';
import { MatDialog } from '@angular/material/dialog';
import { CSATModuleEditComponent } from './csat-module-edit/csat-module-edit.component';

@Component({
  selector: 'settings-module-component',
  templateUrl: './settings-module.component.html',
  styleUrls: ['settings-module.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingsModuleComponent extends AbstractComponent implements OnInit, OnChanges, OnDestroy {

  @Input() channel!: Channel;

  settingsModule: SettingsModule = {} as SettingsModule;

  constructor(
    injector: Injector,
    private service: SettingsModuleService,
    public dialog: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.createForm();
    this.settingsModule = {} as SettingsModule;
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.settingsModule, value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['channel']?.currentValue) {
      this.settingsModule = {} as SettingsModule;
      this.channel = changes['channel']?.currentValue;
      this.getSettingsModule();
    }
  }

  override ngOnDestroy(): void {
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      _id: [null],
      activeCSATModule: [false],
    });
    if (!this.getCompanyCurrentUser().moduleCSAT) {
      this.formGroup.get('activeCSATModule').setValue(false);
      this.formGroup.get('activeCSATModule')?.disable();
    }
    if (!this.isAdmin()) {
      this.formGroup.disable();
    }
  }

  getSettingsModule() {
    this.service.getByChannel(this.channel._id).subscribe({
      next: (value: SettingsModule) => {
        if (value) {
          this.settingsModule = value;
          this.formGroup.patchValue(this.settingsModule);
        } else {
          this.formGroup.get('_id').setValue(null);
          this.formGroup.get('activeCSATModule').setValue(false);
          this.settingsModule.channel = this.channel;
        }
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  isCSATModuleActive() {
    return this.formGroup.get('activeCSATModule').value;
  }

  editCSAT() {
    if (this.isAdmin()) {
      const dialogRef = this.dialog.open(CSATModuleEditComponent, {
        width: '600px',
        data: this.settingsModule
      });

      dialogRef.afterClosed().subscribe(result => {
        this.getSettingsModule();
      });
    }
  }

  onConfirmClick() {
    if (this.settingsModule._id) {
      this.service.update(this.settingsModule._id, this.settingsModule).subscribe({
        next: (value: SettingsModule) => {
          this.alertService.success('Configurações dos módulos atualizadas com sucesso.');
        },
        error: (err) => this.alertService.error(err.error.message),
      });
    } else {
      this.service.create(this.settingsModule).subscribe({
        next: (value: SettingsModule) => {
          this.settingsModule = value;
          this.formGroup.patchValue(this.settingsModule);
          this.alertService.success('Configurações dos módulos atualizadas com sucesso.');
        },
        error: (err) => this.alertService.error(err.error.message),
      });
    }
  }
}
