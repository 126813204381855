import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { WarningWord } from '../models/warning-word.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WarningWordService extends AbstractService<WarningWord> {

  getURLBase(): string {
    return '/warning-word';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: WarningWord): void {
    delete obj?._id;
  }

  preUpdate(obj: WarningWord): void {
  }

}
