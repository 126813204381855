import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Attendance } from '../models/attendance.model';

@Injectable({ providedIn: 'root' })
export class DashboardService extends AbstractService<Attendance> {

  getURLBase(): string {
    return '/dashboard';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Attendance): void {
    delete obj?._id;
  }
  preUpdate(obj: Attendance): void {
  }

  getAttendanceDashboard(dateStart: string, dateEnd: string, department: string, attendant: string, channel: string): Observable<any> {
    return super.get(`?dateStart=${dateStart}&dateEnd=${dateEnd}&department=${department}&attendant=${attendant}&channel=${channel}`);
  }

  getAttendanceDashByHours(dateStart: string, dateEnd: string, department: string, attendant: string, channel: string): Observable<any> {
    return super.get(`/hours?&dateStart=${dateStart}&dateEnd=${dateEnd}&department=${department}&attendant=${attendant}&channel=${channel}`);
  }

  getStatusAttendance(status: string, dateStart: string, dateEnd: string, department: string, attendant: string, channel: string, page: number, limit: number) {
    return super.get(`/attendance/status?status=${status}&dateStart=${dateStart}&dateEnd=${dateEnd}&department=${department}&attendant=${attendant}&channel=${channel}&page=${page}&limit=${limit}`);
  }

  getVolumeMessageHours(hourStart: string, hourEnd: string) {
    return super.get(`/volume/message?hourStart=${hourStart}&hourEnd=${hourEnd}`)
  }

  getPeakAttendance(dateStart: string, dateEnd: string, department: string, attendant: string, channel: string): Observable<any> {
    return super.get(`/peak/attendance?dateStart=${dateStart}&dateEnd=${dateEnd}&department=${department}&attendant=${attendant}&channel=${channel}`);
  }

  getNewContact(dateStart: string, dateEnd: string, department: string, attendant: string, channel: string): Observable<any> {
    return super.get(`/new/contact?dateStart=${dateStart}&dateEnd=${dateEnd}&department=${department}&attendant=${attendant}&channel=${channel}`);
  }
  
  dateDashAttandanceOnline(dateStart: string, dateEnd: string, department: string, attendant: string, channel: string, page: number, limit: number): Observable<any> {
    return super.get(`/attendance/online?dateStart=${dateStart}&dateEnd=${dateEnd}&department=${department}&attendant=${attendant}&channel=${channel}&page=${Number(page)}&limit=${Number(limit)}`);
  }

  dateDashPeakNewContact(dateStart: string, dateEnd: string, department: string, attendant: string, channel: string): Observable<any> {
    return super.get(`/new/contact-user?dateStart=${dateStart}&dateEnd=${dateEnd}&department=${department}&attendant=${attendant}&channel=${channel}`);
  }
}
