import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { CampaignSendMessage } from '../models/campaign-send-message.model';

@Injectable({ providedIn: 'root' })
export class CampaignSendMessageService extends AbstractService<CampaignSendMessage> {

  getURLBase(): string {
    return '/campaign-send-message';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(campaignSendMessage: CampaignSendMessage): void {
    delete campaignSendMessage._id;
  }

  preUpdate(campaignSendMessage: CampaignSendMessage): void {
  }

}
