import { Component, Inject, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { AbstractComponent } from '../../../../commons/abstract.component';
import { SettingsModule } from '../../../../models/settings-module.model';
import { SettingsModuleService } from '../../../../services/settings-module.service';
import { Channel } from '../../../../models/channel.model';
import { Editor, Toolbar } from 'ngx-editor';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'csat-module-edit-component',
  templateUrl: './csat-module-edit.component.html',
  styleUrls: ['./csat-module-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CSATModuleEditComponent extends AbstractComponent implements OnInit, OnChanges, OnDestroy {

  @Input() channel!: Channel;

  settingsModule: SettingsModule = {} as SettingsModule;

  isInactive: boolean = false;

  isInactivityChatbot: boolean = false;

  editor: Editor;

  toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline'],
    ['link'],
  ];

  constructor(
    injector: Injector,
    private service: SettingsModuleService,
    public dialogRef: MatDialogRef<CSATModuleEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SettingsModule,
  ) {
    super(injector);
    this.createForm();
  }

  ngOnInit(): void {
    this.editor = new Editor();
    this.createForm();
    this.settingsModule = {} as SettingsModule;
    this.formGroup.patchValue(this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  override ngOnDestroy(): void {
    this.editor.destroy();
  }

  getSettingsModule() {
    this.service.getByChannel(this.channel._id).subscribe({
      next: (value: SettingsModule) => {
        if(value) {
          this.settingsModule = value;
          this.formGroup.patchValue(this.settingsModule);
        }
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      _id: [null],
      activeCSATModule: [false],
      evaluationQuestionCSAT: ['Como você avalia o atendimento do nosso suporte?', Validators.required],
      messageSendCSAT: ['Muito obrigada pela sua avaliação, se possível, nos avalie no google também.', Validators.required],
      note1CSAT: ['1 - Péssimo', Validators.required],
      note2CSAT: ['2 - Ruim', Validators.required],
      note3CSAT: ['3 - Regular', Validators.required],
      note4CSAT: ['4 - Bom', Validators.required],
      note5CSAT: ['5 - Ótimo', Validators.required],
      channel: [null, Validators.required]
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.settingsModule, value);
    });
  }

  isCSATModuleActive() {
    return this.formGroup.get('activeCSATModule')?.value;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    if (this.settingsModule._id) {
      this.service.update(this.settingsModule._id, this.settingsModule).subscribe({
        next: (value: SettingsModule) => {
          this.alertService.success('Configurações do módulo CSAT atualizadas com sucesso.');
          this.dialogRef.close();
        },
        error: (err) => this.alertService.error(err.error.message),
      });
    } else {
      this.service.create(this.settingsModule).subscribe({
        next: (value: SettingsModule) => {
          this.settingsModule = value;
          this.formGroup.patchValue(this.settingsModule);
          this.alertService.success('Configurações dos módulo CSAT atualizadas com sucesso.');
          this.dialogRef.close();
        },
        error: (err) => this.alertService.error(err.error.message),
      });
    }
  }
}